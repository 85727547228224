.admin-welcome {
  h3, h4 {
    margin-top: 0;
  }

  .nav-tabs {
    li a:hover {
      cursor: pointer;
    }
  }

  ul {
    margin-bottom: 0;
  }

  table.stats {
    margin: 0;
    background: white;
    @rowHeight: 40px;

    td {
      line-height: @rowHeight !important;
      padding: 0 !important;

      &:last-child {
        padding: 0 10px !important;
      }

      a {
        display: block;
        line-height: @rowHeight;
        width: 100%;
        height: 100%;
        padding: 0 10px;
        opacity: 0.8;

        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }
    }

    .row-label {
      text-align: right;
    }
  }
}
