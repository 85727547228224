.firm-attorneys {
  display: block;
  .clearblock;

  & > h3 {
    margin-top: 0;
  }

  .question {
    font-weight: bold;
  }

  .attorneys {
    @firmAttorneySpacing: 15px;

    margin-top: @firmAttorneySpacing;
    background: white;
    padding: @firmAttorneySpacing;

    firm-attorney + firm-attorney {
      margin-top: @firmAttorneySpacing;
      border-top: 1px solid #ddd;
      padding-top: @firmAttorneySpacing;
    }
  }

  .no-results {
    text-align: center;
    line-height: 40px;
  }
}
