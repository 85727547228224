user-action {
  position: relative;
  display: block;
  clear: both;

  & > .user-action {
    background: white;
    border: 1px solid #ddd;
    border-radius: 3px;

    @spacing: 10px;
    @headerHeight: 30px;

    &:hover {
      border-color: #aaa;
      & > .glyphicon {
        opacity: 1;
      }
    }

    & > .glyphicon {
      position: absolute;
      right: 0;
      line-height: @headerHeight;
      width: 30px;
      text-align: center;
      cursor: pointer;
      opacity: 0.6;
    }

    & > table {
      width: 100%;
      line-height: @headerHeight;
      cursor: pointer;

      td {
        padding: 0 @spacing;
      }
    }

    &.isOpen .details {
      display: block;
    }

    .details {
      @detailsFontSize: 14px;

      display: none;
      padding: @spacing;
      border-top: 1px solid #ddd;
      box-shadow: 0 8px 16px -14px black inset;
      background: #f5f5f5;
      @labelWidth: 20%;
      font-size: @detailsFontSize;
      line-height: @detailsFontSize;

      .detail {
        width: 100%;
        position: relative;
        padding-left: @labelWidth;
        box-sizing: border-box;
        clear: both;

        .detail-label {
          position: absolute;
          left: 0;
          top: 0;
          width: @labelWidth;
          text-align: right;
          padding: 0 @spacing;
          box-sizing: border-box;
          line-height: @detailsFontSize + 10px;
        }

        .detail-content {
          color: #666;
          font-size: 12px;
          line-height: @detailsFontSize + 10px;
          .clearblock;

          .data-item {
            float: left;
            background: white;
            margin-bottom: 5px;
            border-radius: 3px;
            clear: both;
            .clearblock;
            @borderColor: #ddd;
            border: 1px solid @borderColor;

            &:hover {
              border-color: darken(@borderColor, 15%);
            }

            .field, .data {
              float: left;
              padding: 0 5px;
            }

            .nulled.data {
              font-style: italic;
              opacity: 0.7;
            }

            .field {
              font-weight: bold;
              min-width: 175px;
              text-align: right;
              border-right: 1px solid @borderColor;
            }
          }

          .method {
            color: blue;
            font-weight: bold;
          }
          .url {
            font-weight: bold;
            color: black;
          }
        }
        &:hover .detail-content {
          color: black;
        }
      }
    }
  }
}

