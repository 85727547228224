.fw-loaded-display > .page {
  padding-top: @pageSpacing;
  padding-bottom: @pageSpacing;

  &.index {
    padding-top: 0;
  }

  & > .content {
    padding: 20px;

    & > .title-bar {
      position: relative;
      border-bottom: 1px solid @header-bg-color;
      padding-bottom: 5px;
      margin-bottom: 20px;

      .goto-link {
        float: right;
        font-weight: normal;
        font-size: 13px;
      }
    }
  }

  &.command > .content {
    padding: 30px 50px;

    h1 {
      position: relative;
      border-bottom: 1px solid @header-bg-color;
      padding-bottom: 5px;
      margin-bottom: 20px;
    }

    form {
      @formWidth: 400px;

      position: relative;
      width: @formWidth;
      margin-left: -(@formWidth / 2);
      left: 50%;
      float: left;
      margin-top: @spacing;
    }
  }

  form label {
    font-weight: normal;
    font-size: 12px;
  }
}

.hidden {
  display: none !important;
}
