.admin-attorney-search {
  @controlsHeight: 40px;
  @pagerLineHeight: 27px;

  .was-created {
    @background: #a5ffa0;

    width: 300px;
    float: right;
    clear: both;
    padding: 20px;
    border-radius: 3px;
    background: @background;
    color: black;
    border: 1px solid darken(@background, 20%);
    line-height: 20px;

    p:first-child {
      font-weight: bold;
    }
    p:last-child {
      margin: 0;
      font-size: 13px;
    }

    & + .row {
      clear: both;
      margin: 0;
      padding-top: 10px;
    }
  }

  input[disabled="disabled"] + span {
    opacity: 0.7;
  }

  .hovertip {
    .hovertip-color(#ccc);

    .hovertip-content {
      left: 5px;
      em {
        font-style: normal;
        color: blue;
        font-weight: bold;
      }
    }
  }

  .control-options {
    margin-bottom: 10px;
    @searchRowHeight: 40px;
    @termHeight: @searchRowHeight - 10px;

    & > .expanded-content {
      height: 0;
      overflow: hidden;
      .clearblock;
    }

    & > .collapsed-content {
      height: auto;
      overflow: visible;
      line-height: 30px;
      text-align: right;
      .clearblock;

      .term {
        height: @termHeight;
        line-height: @termHeight;
        float: left;
        border: 1px solid #ddd;
        position: relative;
        border-radius: 3px;
        background: #e3fcfd;

        .fieldName, .value {
          float: left;
          padding: 0 5px;
        }

        .fieldName {
          border-right: 1px solid #ddd;
        }

        .value {
          font-weight: bold;
        }
      }

      .term + .term {
        margin-left: 5px;
      }

      .toggle-search, .create-attorney {
        float: right;
        margin-left: 10px;
      }
    }

    &.isExpanded {
      & > .expanded-content {
        height: auto;
        overflow: visible;
      }
      & > .collapsed-content {
        height: 0;
        overflow: hidden;
      }
    }

    .search-row {
      width: 100%;
      line-height: 28px;
      clear: both;
      .clearblock;

      .search.button, .toggle-switch {
        float: right;
      }

      &.dual {
        .block {
          width: 50%;
          float: left;
          box-sizing: border-box;

          &:first-child {
            padding-right: 5px;
          }
        }
      }
    }

    .search-label {
      float: left;
      width: 25%;
      text-align: right;
      box-sizing: border-box;
      padding: 0 5px;
      font-size: 15px;
    }

    .search-property {
      float: left;
      width: 75%;
      box-sizing: border-box;

      label + label {
        margin-left: 10px;
      }
      label {
        cursor: pointer;
      }

      input, select {
        width: 100%;
      }

      input[type="checkbox"] {
        width: auto;
        margin-left: 5px;
        outline-style: none;
        position: relative;
        top: 2px;
      }

      .col-md-6:first-child {
        padding: 0 5px 0 0;
      }
    }

    .no-search-terms {
      float: left;
      color: #777;
    }

    .clear-search, .search-now {
      float: right;
    }

    .clear-search {
      margin-right: 5px;
      cursor: pointer;
      color: #777;
      border: 1px solid #ddd;
      padding: 0 5px;
      border-radius: 3px;
      line-height: 28px;
      display: inline-block;
      background: #ddd;

      &:hover {
        color: black;
      }
    }

    .search-now {
      text-align: right;
      margin-bottom: 10px;
    }
  }

  h3, h4 {
    margin-top: 0;
  }

  .well {
    .clearblock;
    padding-top: 0;

    .no-results {
      clear: both;
      text-align: center;
      font-weight: bold;
      padding-top: 10px;
    }
  }

  .list-options, .pages {
    line-height: @controlsHeight;
  }

  .list-options {
    margin: 5px 0;
    .clearblock;

    select {
      width: 100%;
    }

    .sort-options, .results-count-options, .filter-selection {
      float: left;
      box-sizing: border-box;
    }

    .sort-options {
      width: 30%;
      padding-right: 5px;

      .sort-label {
        float: left;
        box-sizing: border-box;
        width: 30%;
        padding-right: 5px;
        text-align: right;
      }

      select {
        width: 70%;
      }
    }

    .results-count-options {
      width: 18%;
      padding-right: 5px;
    }

    .filter-selection {
      width: 52%;
    }
  }

  hr {
    margin: 5px 0;
    display: block;
    clear: both;
  }

  .range-display {
    float: right;
    line-height: @pagerLineHeight;

    .range-label, .total {
      font-weight: bold;
    }
  }

  .loading-results {
    clear: both;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    padding: 50px 0;

    .message, .glyphicon {
      font-size: 15px;
      line-height: 20px;
    }

    .glyphicon {
      margin-top: 10px;
      margin-left: 5px;
    }
  }

  .pages {
    float: left;
    clear: both;
    .clearblock;
    user-select: none;
    position: relative;
    margin-bottom: 5px;

    @hoverPageLinkColor: black;
    @pageLinkColor: lighten(@hoverPageLinkColor, 44%);

    .page {
      float: left;
      color: @pageLinkColor;
      width: 25px;
      text-align: center;
      border: 1px solid transparent;
      border-radius: 3px;
      line-height: @pagerLineHeight - 2px;

      &.next {
        border-left: 1px solid @border-color;
        margin-left: 5px;
      }

      &.previous {
        border-right: 1px solid @border-color;
        margin-right: 5px;
      }

      &.clickable {
        cursor: pointer;

        &.outOfBounds {
          display: none;
        }

        &:hover {
          color: @hoverPageLinkColor;
        }

        &:active {
          color: @pageLinkColor;
        }

        &.active {
          color: @hoverPageLinkColor;
          background: white;
          border-color: #ccc;
          box-shadow: 0 1px 3px -1px black;
        }
      }
    }
  }
}
