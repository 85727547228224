.finish {
  position: relative;

  p {
    font-weight: bold;
  }

  iframe {
    width: 100%;
    height: 500px;
    border: 1px solid #ddd;
    background: #f5f5f5;
  }

  .finalize-row {
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
  }

  em {
    color: @primaryColor;
  }
}