nav {
  @background: white;
  @textColor: contrast(@header-bg-color);
  @radiusSize: 3px;

  a:focus {
    outline-style: none;
    text-decoration: none;
  }

  .clearblock;

  @totalCasesColor: rgba(0,0,0,0.2);
  @bubbleSize: 23px;
  @bubbleTextColor: contrast(@totalCasesColor, black, white, 30%);
  .num-cases {
    color: fadeout(@bubbleTextColor, 50%);
    background: @totalCasesColor;
    line-height: @bubbleSize;
    height: @bubbleSize;
    display: inline-block;
    width: @bubbleSize;
    text-align: center;
    border-radius: @bubbleSize;
    margin-left: 5px;
  }

  .cases {
    display: none;
  }
  &.isLoggedIn .cases {
    display: block;
  }

  .cases.item {
    width: 150px;
  }

  .item {
    float: left;
    line-height: @nav-height;
    cursor: pointer;
    color: #f5c8b9;
    font-size: 13px;
    text-align: center;
    width: 120px;
    overflow: hidden;
    position: relative;

    &:first-child {
      width: 100px;
      float: left;
    }

    .glyphicon {
      margin-right: 10px;
    }

    &.icon {
      width: 35px;
    }

    .ripple-box {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.small {
      width: auto;
      &:before {
        margin: 0;
        padding: 0 10px;
      }
    }

    &:first-child {
      border-radius: 0 0 0 @radiusSize;
    }
    &:last-child {
      border-radius: 0 @radiusSize 0 0;
    }

    &:before {
      margin-right: @spacing;
    }

    &.right {
      float: right;
    }

    &:hover, &.active {
      color: white;
      text-decoration: none;

      .num-cases {
        color: @bubbleTextColor;
      }
    }

    &:active, &.active {
      background: rgba(0,0,0,0.1);
    }
  }
}

// @media screen and (max-width: 780px) {
//   nav {
//     .item {
//       font-size: 13px;
//       width: 85px;
//     }
//   }
// }
