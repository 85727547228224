.questionnaire {
  max-width: 800px;
  font: 13px arial;
  text-align: left;

  .main-group {
    & > .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
    }

    & > .content {
      border: 1px solid @border-color;
      border-radius: 3px;
    }
  }

  .main-group + .main-group {
    margin: @spacing 0;
  }

  question-display + question-display {
    .questionDisplay {
      border-top: 1px solid @border-color;
    }
  }

  .questionDisplay {
    padding: @spacing;

    & > div {
      .clearblock;
    }

    & .questionDisplay {
      border: none;
      background: fadeout(black, 97%);
      border: 1px solid fadeout(black, 90%);
      margin-top: @spacing;
      border-radius: 3px;
    }
  }

  .answerDisplay {
    margin-top: @spacing / 2;
    border-radius: 3px;
    float: left;
  }

  .answer, .answerDisplay {
    padding: 0 10px;
    line-height: 25px;
    background: lighten(@border-color, 15%);
  }

  .answerDisplay {
    border: 1px solid @border-color;
    display: none;
  }

  .hasAnswer > div > .answerDisplay {
    display: block;
  }

  .hashMapAnswer {
    margin-top: @spacing / 2;
    float: left;
    border: 1px solid @border-color;
    border-radius: 3px;
    line-height: 25px;

    .label {
      float: left;
      font-weight: bold;
      padding: 0 10px;
      width: auto !important;
      box-sizing: border-box;
    }

    .answer {
      border-left: 1px solid @border-color;
      float: right;
      white-space: nowrap;
      box-sizing: border-box;
    }
  }
  .hashMapAnswer + .hashMapAnswer {
    margin-left: @spacing / 2;
  }

  .arrayAnswer {
    margin-top: @spacing / 2;
    float: left;
    border-radius: 3px;

    .answer {
      border: 1px solid @border-color;
    }
    .answer + .answer {
      margin-top: @spacing / 2;
    }
  }

  .questionText {
    color: #555;
  }
}
