firm-attorney {
  display: block;
  .clearblock;

  @lightText: #666;

  .participating-selector {
    cursor: pointer;
    user-select: none;
    color: @lightText;

    input {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    &:hover, &.isParticipating {
      color: black;
    }
  }

  .submit.row {
    padding-top: 0 !important;
    margin-top: 0 !important;
    border-top: none !important;

    .button, .remove {
      float: right;
    }

    .remove {
      margin-right: 10px;
      cursor: pointer;
      font-size: 12px;
      color: @lightText;
      line-height: 31px;
      user-select: none;

      &:hover {
        color: black;
      }
    }
  }
}
