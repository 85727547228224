user-actions {
  display: block;

  .user-actions {
    @controlsHeight: 40px;
    @pagerLineHeight: 27px;

    user-action + user-action {
      margin-top: 5px;
    }

    h3, h4 {
      margin-top: 0;
    }

    .title-table {
      clear: both;
      width: 100%;
      margin-bottom: 5px;

      td {
        font-weight: bold;
      }
    }

    table {
      td {
        box-sizing: border-box;
        padding: 0 10px;
      }

      td.date {
        width: 15%;
      }
      td.action-label {
        width: 30%;
      }
      td.attorney {
        width: 27.5%;
      }
      td.performed-by {
        width: 27.5%;
      }
    }

    .top-section {
      margin: 10px 0;
      background: rgba(0,0,0,0.1);
      padding: 10px;
      border-radius: 3px;
    }

    .search-options {
      margin-bottom: 10px;
      @searchRowHeight: 40px;
      @termHeight: @searchRowHeight - 10px;

      & > .expanded-content {
        height: 0;
        overflow: hidden;
        .clearblock;
      }

      & > .collapsed-content {
        height: auto;
        overflow: visible;
        line-height: 40px;
        text-align: right;
        .clearblock;

        .term {
          height: @termHeight;
          line-height: @termHeight;
          float: left;
          border: 1px solid #ddd;
          position: relative;
          border-radius: 3px;
          top: (@searchRowHeight - @termHeight) / 2;

          .fieldName, .value {
            float: left;
            padding: 0 5px;
          }

          .fieldName {
            border-right: 1px solid #ddd;
          }

          .value {
            font-weight: bold;
          }
        }

        .toggle-search {
          float: right;
          cursor: pointer;
          color: #777;

          .glyphicon {
            position: relative;
            top: 2px;
            font-size: 20px;
          }

          .glyphicon-search {
            font-size: 15px;
          }

          &:hover {
            color: black;
          }
        }
      }

      &.isExpanded {
        & > .expanded-content {
          height: auto;
          overflow: visible;
        }
        & > .collapsed-content {
          height: 0;
          overflow: hidden;
        }
      }

      .search-row {
        width: 100%;
        line-height: 28px;
        clear: both;
        .clearblock;

        .search.button, .toggle-switch {
          float: right;
        }

        &.dual {
          .block {
            width: 50%;
            float: left;
            box-sizing: border-box;

            &:first-child {
              padding-right: 5px;
            }
          }
        }
      }

      .search-label {
        float: left;
        width: 25%;
        text-align: right;
        box-sizing: border-box;
        padding: 0 5px;
        font-size: 15px;
      }

      .search-property {
        float: left;
        width: 75%;
        box-sizing: border-box;

        input, select {
          width: 100%;
        }
      }

      .no-search-terms {
        float: left;
        color: #777;
      }

      .clear-search {
        margin-right: 5px;
        cursor: pointer;
        color: #777;
        border: 1px solid #ddd;
        padding: 0 5px;
        border-radius: 3px;
        line-height: 31px;
        display: inline-block;

        &:hover {
          color: black;
        }
      }

      .search-now {
        text-align: right;
        margin-bottom: 10px;
      }
    }

      .no-results {
        clear: both;
        text-align: center;
        font-weight: bold;
        padding: 20px 0;
      }

    .pages {
      line-height: @controlsHeight;
    }

    .list-options {
      margin-top: 5px;
      clear: both;
      .clearblock;

      select {
        width: 100%;
      }

      .sort-options, .results-count-options, .filter-selection {
        float: left;
        box-sizing: border-box;
      }

      .sort-options {
        width: 30%;
        padding-right: 5px;

        .sort-label {
          float: left;
          box-sizing: border-box;
          width: 30%;
          padding-right: 5px;
          text-align: right;
          line-height: 28px;
        }

        select {
          width: 70%;
        }
      }

      .results-count-options {
        width: 18%;
        padding-right: 5px;
      }

      .filter-selection {
        width: 52%;
      }
    }

    hr {
      margin: 5px 0;
      display: block;
      clear: both;
    }

    .range-display {
      float: right;
      line-height: @pagerLineHeight;

      .range-label, .total {
        font-weight: bold;
      }
    }

    .loading-results {
      clear: both;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
      padding: 50px 0;

      .message, .glyphicon {
        font-size: 15px;
        line-height: 20px;
      }

      .glyphicon {
        margin-top: 10px;
        margin-left: 5px;
      }
    }

    .pages {
      float: left;
      clear: both;
      .clearblock;
      user-select: none;
      position: relative;
      margin-bottom: 10px;

      @hoverPageLinkColor: black;
      @pageLinkColor: lighten(@hoverPageLinkColor, 44%);

      .page {
        float: left;
        color: @pageLinkColor;
        width: 25px;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 3px;
        line-height: @pagerLineHeight - 2px;

        &.next {
          border-left: 1px solid @border-color;
          margin-left: 5px;
        }

        &.previous {
          border-right: 1px solid @border-color;
          margin-right: 5px;
        }

        &.clickable {
          cursor: pointer;

          &.outOfBounds {
            display: none;
          }

          &:hover {
            color: @hoverPageLinkColor;
          }

          &:active {
            color: @pageLinkColor;
          }

          &.active {
            color: @hoverPageLinkColor;
            background: white;
            border-color: #ccc;
            box-shadow: 0 1px 3px -1px black;
          }
        }
      }
    }
  }
}

