.admin-config {

  .row {
    line-height: 28px;
    padding: 0;
  }

  .row + .row {
    margin-top: 10px;
  }

  .row-label {
    font-weight: bold;
    clear: both;

    .desc {
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      opacity: 0.8;
    }

    &.spaced {
      padding-top: 28px;
    }
  }

  .stale-input.row {
    .pre.text {
      display: inline-block;
      width: 200px;
      text-align: right;
    }

    input {
      width: 30px;
      text-align: center;
    }

    .post.text {
      display: inline-block;
    }
  }

  .submit.row {
    text-align: right;
    clear: both;
    margin-top: 20px;
  }
}
