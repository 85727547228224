@font-face {
    font-family: 'file-icons';
    src:    url('/styles/include/file-icons/file-icons.eot?8fkda6');
    src:    url('/styles/include/file-icons/file-icons.eot?8fkda6#iefix') format('embedded-opentype'),
        url('/styles/include/file-icons/file-icons.ttf?8fkda6') format('truetype'),
        url('/styles/include/file-icons/file-icons.woff?8fkda6') format('woff'),
        url('/styles/include/file-icons/file-icons.svg?8fkda6#file-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="file-icon-"], [class*=" file-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'file-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.file-icon-dm-file-format:before {
    content: "\e900";
}
.file-icon-ds-file-format-symbol:before {
    content: "\e901";
}
.file-icon-g2-file-format-symbol:before {
    content: "\e902";
}
.file-icon-gp-file-format-variant:before {
    content: "\e903";
}
.file-icon-z-file-format-variant:before {
    content: "\e904";
}
.file-icon-aac-file-format:before {
    content: "\e905";
}
.file-icon-aif-file-format:before {
    content: "\e906";
}
.file-icon-ai-file-format-symbol:before {
    content: "\e907";
}
.file-icon-angel:before {
    content: "\e908";
}
.file-icon-apk-file-format:before {
    content: "\e909";
}
.file-icon-app-file-format-variant:before {
    content: "\e90a";
}
.file-icon-asf-file-format-variant:before {
    content: "\e90b";
}
.file-icon-asp-file-format-symbol:before {
    content: "\e90c";
}
.file-icon-aspx-file-format:before {
    content: "\e90d";
}
.file-icon-asx-multimedia-file-format:before {
    content: "\e90e";
}
.file-icon-avi-file-format-variant:before {
    content: "\e90f";
}
.file-icon-bak-file-format-symbol:before {
    content: "\e910";
}
.file-icon-bat-file-format-symbol:before {
    content: "\e911";
}
.file-icon-bin-file-format:before {
    content: "\e912";
}
.file-icon-blank-file:before {
    content: "\e913";
}
.file-icon-bmp-file-format-symbol:before {
    content: "\e914";
}
.file-icon-cab-file-format:before {
    content: "\e915";
}
.file-icon-cad-file-format-symbol:before {
    content: "\e916";
}
.file-icon-cdr-file-format-symbol:before {
    content: "\e917";
}
.file-icon-cer-file-format:before {
    content: "\e918";
}
.file-icon-cfg-file-format-symbol:before {
    content: "\e919";
}
.file-icon-cfm-file-format-symbol:before {
    content: "\e91a";
}
.file-icon-cgi-file-format-symbol:before {
    content: "\e91b";
}
.file-icon-class-file-format-variant:before {
    content: "\e91c";
}
.file-icon-com-file-format-symbol:before {
    content: "\e91d";
}
.file-icon-cpl-file-format-variant:before {
    content: "\e91e";
}
.file-icon-cpp-file-format-symbol:before {
    content: "\e91f";
}
.file-icon-crx-file-format-symbol:before {
    content: "\e920";
}
.file-icon-csr-file-format:before {
    content: "\e921";
}
.file-icon-css-file-format-symbol:before {
    content: "\e922";
}
.file-icon-csv-file-format-symbol:before {
    content: "\e923";
}
.file-icon-cue-file-format-symbol:before {
    content: "\e924";
}
.file-icon-cur-file-format:before {
    content: "\e925";
}
.file-icon-dat-file-format-variant:before {
    content: "\e926";
}
.file-icon-dbf-file-format-symbol:before {
    content: "\e927";
}
.file-icon-db-file-format-variant:before {
    content: "\e928";
}
.file-icon-dds-file-format-symbol:before {
    content: "\e929";
}
.file-icon-debian-file:before {
    content: "\e92a";
}
.file-icon-dem-file-format-symbol:before {
    content: "\e92b";
}
.file-icon-demon:before {
    content: "\e92c";
}
.file-icon-dll-file-format-variant:before {
    content: "\e92d";
}
.file-icon-dmg-file-format-symbol:before {
    content: "\e92e";
}
.file-icon-dmp-file-format-symbol:before {
    content: "\e92f";
}
.file-icon-doc-file-format-symbol:before {
    content: "\e930";
}
.file-icon-docx-file-format:before {
    content: "\e931";
}
.file-icon-drv-file-format-variant:before {
    content: "\e932";
}
.file-icon-dtd-file-format-extension:before {
    content: "\e933";
}
.file-icon-dwg-file-format-variant:before {
    content: "\e934";
}
.file-icon-dxf-file-format-symbol:before {
    content: "\e935";
}
.file-icon-elf-file:before {
    content: "\e936";
}
.file-icon-elf-file-format-variant:before {
    content: "\e937";
}
.file-icon-eml-file:before {
    content: "\e938";
}
.file-icon-eps-file-format-symbol:before {
    content: "\e939";
}
.file-icon-eps-file-format-variant:before {
    content: "\e93a";
}
.file-icon-exe-file-format-symbol:before {
    content: "\e93b";
}
.file-icon-fla-file-format-variant:before {
    content: "\e93c";
}
.file-icon-flash-file-format:before {
    content: "\e93d";
}
.file-icon-flv-file-format-symbol:before {
    content: "\e93e";
}
.file-icon-fnt-file-format:before {
    content: "\e93f";
}
.file-icon-fon-file-format-symbol:before {
    content: "\e940";
}
.file-icon-gam-file-format-variant:before {
    content: "\e941";
}
.file-icon-gbr-file-format-extension:before {
    content: "\e942";
}
.file-icon-ged-file-format-symbol:before {
    content: "\e943";
}
.file-icon-gif-file-format:before {
    content: "\e944";
}
.file-icon-gpx-file-format-variant:before {
    content: "\e945";
}
.file-icon-gz-file-format-symbol:before {
    content: "\e946";
}
.file-icon-gzip-file-format-variant:before {
    content: "\e947";
}
.file-icon-hqz-file-format:before {
    content: "\e948";
}
.file-icon-html-file-with-code-symbol:before {
    content: "\e949";
}
.file-icon-ibooks-file-format-symbol:before {
    content: "\e94a";
}
.file-icon-icns-file-format:before {
    content: "\e94b";
}
.file-icon-ico-file-format-variant:before {
    content: "\e94c";
}
.file-icon-ics-file-format-symbol:before {
    content: "\e94d";
}
.file-icon-iff-file-format:before {
    content: "\e94e";
}
.file-icon-indd-file-format-variant:before {
    content: "\e94f";
}
.file-icon-ipa-file:before {
    content: "\e950";
}
.file-icon-iso-file-format:before {
    content: "\e951";
}
.file-icon-iso-file-format-symbol:before {
    content: "\e952";
}
.file-icon-jar-file-format:before {
    content: "\e953";
}
.file-icon-jpg-image-file-format:before {
    content: "\e954";
}
.file-icon-js-file-format-symbol:before {
    content: "\e955";
}
.file-icon-jsp-file-format-symbol:before {
    content: "\e956";
}
.file-icon-key-file-format-variant:before {
    content: "\e957";
}
.file-icon-kml-file-format-variant:before {
    content: "\e958";
}
.file-icon-kmz-file-format-symbol:before {
    content: "\e959";
}
.file-icon-lnk-file-format-symbol:before {
    content: "\e95a";
}
.file-icon-log-file-format:before {
    content: "\e95b";
}
.file-icon-lua-file-format-symbol:before {
    content: "\e95c";
}
.file-icon-m3u-file-format:before {
    content: "\e95d";
}
.file-icon-m4a-file-format-symbol:before {
    content: "\e95e";
}
.file-icon-m4v-file-format-variant:before {
    content: "\e95f";
}
.file-icon-mach-o-file:before {
    content: "\e960";
}
.file-icon-mach-o-file-format:before {
    content: "\e961";
}
.file-icon-max-file-format-variant:before {
    content: "\e962";
}
.file-icon-mdb-file-format-symbol:before {
    content: "\e963";
}
.file-icon-mdf-file-format-variant:before {
    content: "\e964";
}
.file-icon-mid-file-format:before {
    content: "\e965";
}
.file-icon-mim-file-format:before {
    content: "\e966";
}
.file-icon-mov-file-format-symbol:before {
    content: "\e967";
}
.file-icon-mp3-file-format-variant:before {
    content: "\e968";
}
.file-icon-mp4-file-format-symbol:before {
    content: "\e969";
}
.file-icon-mpa-file-format:before {
    content: "\e96a";
}
.file-icon-mpg-file-format-variant:before {
    content: "\e96b";
}
.file-icon-msg-file:before {
    content: "\e96c";
}
.file-icon-msg-file-format-variant:before {
    content: "\e96d";
}
.file-icon-msi-file-format-symbol:before {
    content: "\e96e";
}
.file-icon-nes-file-variant:before {
    content: "\e96f";
}
.file-icon-object-file-format:before {
    content: "\e970";
}
.file-icon-odb-file-format-variant:before {
    content: "\e971";
}
.file-icon-odc-file-format-symbol:before {
    content: "\e972";
}
.file-icon-odf-file-format-variant:before {
    content: "\e973";
}
.file-icon-odg-file-format:before {
    content: "\e974";
}
.file-icon-odi-file-format-symbol:before {
    content: "\e975";
}
.file-icon-odp-file-format-symbol:before {
    content: "\e976";
}
.file-icon-ods-file-format-symbol:before {
    content: "\e977";
}
.file-icon-odt-file-format:before {
    content: "\e978";
}
.file-icon-odt-file-format-symbol:before {
    content: "\e979";
}
.file-icon-odx-file-format-extension:before {
    content: "\e97a";
}
.file-icon-ogg-file-format-symbol:before {
    content: "\e97b";
}
.file-icon-otf-file-format:before {
    content: "\e97c";
}
.file-icon-otf-file-format-extension:before {
    content: "\e97d";
}
.file-icon-pages-file-format-symbol:before {
    content: "\e97e";
}
.file-icon-pct-file-format-symbol:before {
    content: "\e97f";
}
.file-icon-pdb-file-format-variant:before {
    content: "\e980";
}
.file-icon-pdf-file-format-symbol:before {
    content: "\e981";
}
.file-icon-pif-file-format-variant:before {
    content: "\e982";
}
.file-icon-pkg-file-format-variant:before {
    content: "\e983";
}
.file-icon-pl-file-format-variant:before {
    content: "\e984";
}
.file-icon-png-file-extension-interface-symbol:before {
    content: "\e985";
}
.file-icon-pps-file-format-symbol:before {
    content: "\e986";
}
.file-icon-ppt-file-format:before {
    content: "\e987";
}
.file-icon-pptx-file-format-variant:before {
    content: "\e988";
}
.file-icon-psd-file-format-variant:before {
    content: "\e989";
}
.file-icon-ps-file-format:before {
    content: "\e98a";
}
.file-icon-pub-file-format-symbol:before {
    content: "\e98b";
}
.file-icon-python-file-symbol:before {
    content: "\e98c";
}
.file-icon-ra-file-format:before {
    content: "\e98d";
}
.file-icon-rar-file-format:before {
    content: "\e98e";
}
.file-icon-raw-file-format-symbol:before {
    content: "\e98f";
}
.file-icon-rm-file-format:before {
    content: "\e990";
}
.file-icon-rom-file:before {
    content: "\e991";
}
.file-icon-rom-file-format-variant:before {
    content: "\e992";
}
.file-icon-rpm-file-format-symbol:before {
    content: "\e993";
}
.file-icon-rss-file-format-symbol:before {
    content: "\e994";
}
.file-icon-rtf-icon-format:before {
    content: "\e995";
}
.file-icon-sav-file-format:before {
    content: "\e996";
}
.file-icon-sdf-file-format:before {
    content: "\e997";
}
.file-icon-sitx-file-format-variant:before {
    content: "\e998";
}
.file-icon-sql-file-format-symbol:before {
    content: "\e999";
}
.file-icon-sql-file-format-symbol-1:before {
    content: "\e99a";
}
.file-icon-srt-file-format-symbol:before {
    content: "\e99b";
}
.file-icon-svg-file-format-symbol:before {
    content: "\e99c";
}
.file-icon-swf-file-format-symbol:before {
    content: "\e99d";
}
.file-icon-sys-file-format:before {
    content: "\e99e";
}
.file-icon-tar-file-variant:before {
    content: "\e99f";
}
.file-icon-tex-file-format:before {
    content: "\e9a0";
}
.file-icon-tga-file-format-symbol:before {
    content: "\e9a1";
}
.file-icon-thm-file-format-symbol:before {
    content: "\e9a2";
}
.file-icon-tiff-images-file-extension-symbol-for-interface:before {
    content: "\e9a3";
}
.file-icon-tmp-icon-file-format:before {
    content: "\e9a4";
}
.file-icon-torrent-file-format:before {
    content: "\e9a5";
}
.file-icon-ttf-file-format-symbol:before {
    content: "\e9a6";
}
.file-icon-txt-text-file-extension-symbol:before {
    content: "\e9a7";
}
.file-icon-uue-file-format-symbol:before {
    content: "\e9a8";
}
.file-icon-vb-file-symbol:before {
    content: "\e9a9";
}
.file-icon-vcd-file-format-symbol:before {
    content: "\e9aa";
}
.file-icon-vcf-file-format-variant:before {
    content: "\e9ab";
}
.file-icon-vob-file-format-symbol:before {
    content: "\e9ac";
}
.file-icon-wav-file-format-variant:before {
    content: "\e9ad";
}
.file-icon-wma-file-format-symbol:before {
    content: "\e9ae";
}
.file-icon-wmv-file-format-extension:before {
    content: "\e9af";
}
.file-icon-wpd-file-format-symbol:before {
    content: "\e9b0";
}
.file-icon-wps-file-format:before {
    content: "\e9b1";
}
.file-icon-wsf-file-format-variant:before {
    content: "\e9b2";
}
.file-icon-xhtml-icon-file-format:before {
    content: "\e9b3";
}
.file-icon-xlr-file-format-variant:before {
    content: "\e9b4";
}
.file-icon-xls-file-format-symbol:before {
    content: "\e9b5";
}
.file-icon-xlsx-file-format:before {
    content: "\e9b6";
}
.file-icon-xml-file-format-variant:before {
    content: "\e9b7";
}
.file-icon-yuv-file-format-variant:before {
    content: "\e9b8";
}
.file-icon-zip-compressed-files-extension:before {
    content: "\e9b9";
}
.file-icon-zip-file:before {
    content: "\e9ba";
}

