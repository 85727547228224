@clickableCardHeight: 35px;
@titleHeight: 50px;
@cardBackground: white;
@activeCardColor: #4A4A4A;
@cardSpacing: 15px;

.card {
  background: @cardBackground;
  box-sizing: border-box;
  border: 1px solid #ddd;

  &.focus {
    background: darken(@cardBackground, 25%);
  }

  & > .title, & > .content {
    padding: 0 @cardSpacing;
  }

  &.no-title {
    & > .content {
      padding: @cardSpacing;
    }
  }

  & > .title {
    font-weight: bold;
    font-size: 20px;
    line-height: @titleHeight - 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    color: @activeCardColor;

    &:before {
      padding-right: @cardSpacing;
      margin: 0;
    }
  }

  & > .content {
    padding: 0 @cardSpacing @cardSpacing;
  }

  &.clickable {
    overflow: hidden;
    position: relative;

    .rippler {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-sizing: border-box;
    }

    &:hover {
      text-decoration: none;
      background: darken(@cardBackground, 15%);
      border-color: rgba(0,0,0,0.1);
    }

    &:focus {
      background: darken(@cardBackground, 25%);
      border-color: rgba(0,0,0,0.1);
    }

    &:active, &.active {
      border-color: rgba(0,0,0,0.1);
      color: black !important;
      background: darken(@cardBackground, 25%);

      &:before {
        color: @activeCardColor;
        opacity: 1 !important;
      }
    }

    &:before {
      float: left;
      line-height: @clickableCardHeight;
      padding-left: 5px;
      font-size: 20px;
      opacity: 0.5;
    }
  }
}

.card + .card {
  margin-top: 20px;
  &.clickable {
    margin-top: 0;
  }
}
