header user-menu .user-menu {
  position: absolute;
  bottom: 15px;
  right: 5px;
  z-index: 6;

  .message, .buttons {
    a, .admin-menu-container {
      color: #888;
      border-radius: 3px;
      margin-left: 5px;
      white-space: nowrap;
      user-select: none;

      &:hover, &.active {
        color: black;
      }
    }
  }

  .message {
    margin-bottom: 0px;
  }
}

user-menu {
  display: block;
  .clearblock;

  .user-menu {
    @backgroundColor: white;
    float: right;
    position: relative;

    .admin-menu-container {
      display: inline-block;
      position: relative;
    }

    @adminSpacing: 5px;
    @adminMenuBackground: #f0f0f0;
    .buttons .admin-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 6;
      background: @adminMenuBackground;
      margin-top: 5px;
      box-shadow: 0 1px 25px -8px black;
      border-radius: 3px;
      max-width: none;
      width: 250px;
      box-sizing: border-box;
      border: 1px solid darken(@adminMenuBackground, 15%);

      hr {
        margin: 0;
      }

      @menuLineHeight: 30px;
      a {
        display: block;
        clear: both;
        line-height: @menuLineHeight;
        color: #555;
        float: none;
        margin: 0;
        padding: 0 @adminSpacing;
        text-align: left;
        border-radius: 0;

        .glyphicon {
          float: left;
          top: 0;
          width: 25px;
          text-align: center;
          line-height: @menuLineHeight;
          padding-right: @adminSpacing;
        }
      }
    }

    .not-logged-in, .logged-in-display {
      font-weight: bold;
      border-radius: 3px;
    }

    .buttons {
      line-height: 25px;
      height: 25px;
    }

    .logged-in-display {
      position: relative;
      margin-right: 80px;

      .profile-image {
        position: absolute;
        bottom: @nav-height;
        right: 0;
        z-index: 1;
        opacity: 0.1;

        img {
          display: block;
        }
      }

      .message {
        float: right;
        font-size: 15px;
      }
    }

    .message, .buttons {
      position: relative;
      z-index: 2;
    }

    .not-logged-in {
      padding-right: 20px;
    }

    .message {
      text-align: right;
      font-style: italic;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 5px;
      color: #777;
      white-space: nowrap;
      right: 0;
      bottom: 100%;
    }

    .buttons {
      @height: 25px;
      .clearblock;

      a, .admin-menu-container {
        display: block;
        float: right;
        text-align: center;
        .box-sizing(border-box);
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        padding: 0 5px;
        .box-sizing(border-box);

        &:before {
          margin-right: 5px;
        }

        &:active, &.active, &:focus {
          background: rgba(0,0,0,0.1);
          text-decoration: none;
        }

        &:hover {
          text-decoration: none;
          background: rgba(0,0,0,0.1);

          &:before {
            text-decoration: none;
          }
        }

        &.logout {
          padding-right: 0;

          &:active, &.active, &:focus {
            background: transparent;
          }

          &:hover {
            background: transparent;
          }
        }
      }

      a.login {
        display: block;
        float: right;
        margin-left: 5px;

        &:before {
          font-size: 15px;
          position: relative;
          top: 1px;
        }
      }

      a.user, a.admin {
        text-align: center;

        .rippler {
          position: absolute;
          top: 0;
          left: 0;
        }

        &:before {
          margin: 0;
        }
      }
    }
  }
}
