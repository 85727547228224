register {
  .component-style;
  border-top: 1px solid black;
  padding-top: 20px;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .register {
    @textWidth: 30%;

    &.registrationError {
      .registration-error {
        opacity: 1;
        height: auto;
        margin-top: 10px;
        transition: all 0.2s ease;
      }
    }

    .registration-error {
      @background: crimson;
      @foreground: contrast(@background);

      margin-top: 0px;
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition: all 0.2s ease;
      background: @background;
      color: @foreground;
      font-weight: bold;
      text-align: center;
      border-radius: @radius;
      line-height: 35px;

      &:before {
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-right: @spacing;
      }

      .messages {
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
        border-top: 1px solid rgba(0,0,0,0.2);
        background: rgba(0,0,0,0.1);
        color: mix(@background, @foreground, 30%);
        border-radius: 0 0 @radius @radius;
      }
    }

    label {
      display: block;
      line-height: 30px;

      .text {
        display: block;
        float: left;
        width: @textWidth;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
      }

      input[type="text"], input[type="password"], textarea {
        width: 100% - @textWidth;
        box-sizing: border-box;
      }
    }

    .submit.row {
      margin-top: 10px;
      text-align: right;
    }
  }
}
