user-login {
  .component-style;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .user-login {
    @height: 70px;

    position: relative;
    padding-right: @spacing;
    margin: 10px 0 0 0;

    label {
      input[type="checkbox"] {
        height: 15px;
      }
    }

    .redirect-notice {
      @iconWidth: 40px;

      clear: both;
      line-height: 25px;
      font-size: 12px;
      background: white;
      padding: 5px;
      border: 1px solid @border-color;
      margin-top: @spacing;
      border-radius: @radius;
      position: relative;
      text-align: center;

      .glyphicon {
        font-size: 20px;
        margin: 0 5px;
        position: relative;
        top: 3px;
      }

      .url {
        font-style: italic;
      }
    }

    &.invalidLogin {
      .invalid-login {
        opacity: 1;
        height: auto;
        margin-top: 10px;
        transition: all 0.2s ease;
      }
    }

    .invalid-login {
      margin-top: 0px;
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition: all 0.2s ease;
      background: crimson;
      color: white;
      font-weight: bold;
      text-align: center;
      border-radius: @radius;

      &:before {
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-right: @spacing;
      }
    }

    form {
      line-height: @height / 2;
      max-width: 400px;
      margin: 0 auto;

      .row {
        .clearblock;

        label {
          @textWidth: 25%;
          .clearblock;

          .text {
            float: left;
            width: @textWidth;
            text-align: right;
            padding-right: @spacing;
            box-sizing: border-box;
          }
          input[type="text"], input[type="password"] {
            float: left;
            width: 100% - @textWidth;
          }
        }
      }

      .forgot-password, .register-new {
        line-height: 20px;
        float: right;
        clear: both;
        font-size: 12px;
      }

      .register-new {
        margin-top: @spacing;
      }

      input[type="text"], input[type="password"] {
        display: block;
        width: 100%;
        box-sizing: border-box;
      }

      input + input {
        margin-top: 5px;
      }

      button.submit {
        display: block;
        float: right;
      }

      .submit.row {
        @lineHeight: 30px;
        line-height: @lineHeight;
        text-align: right;
        margin-top: 15px;
        .clearblock;

        button {
          line-height: @lineHeight - 2px;
          &:before {
            margin-right: 5px;
          }
        }

        label {
          display: block;
          padding-right: @spacing;
          cursor: pointer;
          font-size: 12px;
          float: right;
          color: #777;
          user-select: none;

          &:hover, &:focus, &:active {
            color: black;
          }

          input[type="checkbox"] {
            position: relative;
            top: 2px;

            &:focus, &:active {
              background-color: red;
            }
          }
        }

        .cancel {
          cursor: pointer;
          padding: 5px;
          float: right;
          margin-right: 5px;
          border: 1px solid transparent;
          border-radius: @radius;
          user-select: none;
          color: #888;
          position: relative;
          top: 4px;

          &:hover {
            color: black;
            border-color: @border-color;
          }
          &:active {
            background: #dedede;
          }
        }
      }
    }
  }

}
