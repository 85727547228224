benefits-language {
  @benefitsBackground: #dedede;

  display: block;
  min-height: 490px;

  .sample-benefits-pdf {
    display: block;
    float: left;
    clear: both;
    margin: 10px 0;
    opacity: 0.9;

    .icon {
      margin-right: 5px;
      font-size: 15px;
    }

    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }

  .title.item {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;

    .glyphicon {
      margin-right: 10px;
    }
  }

  .section {
    .expandable-color(@benefitsBackground);
    margin-top: 10px;
    padding: 15px;
    border: 1px solid darken(@benefitsBackground, 10%);
    border-radius: 3px;
    background: @benefitsBackground;
    clear: both;

    p:last-child {
      margin-bottom: 20px;
    }

    .expand-toggle {
      .control {
        font-weight: bold;
        color: #4159EC;
      }
    }

    & > .title {
      font-weight: bold;
      font-size: 18px;
    }

    & > .content {
      padding-bottom: 20px;
    }

    &.elder > .content {
      padding-bottom: 0;

      ul {
        margin-bottom: 0;
      }
    }
  }
}
