.attorney-education-and-licensing {
  display: block;
  .clearblock;

  & > h3 {
    margin-top: 0;
  }

  .question {
    font-weight: bold;
  }

  .spaced-row + .question {
    margin-top: 20px;
  }

  .licenses, .degrees {
    @spacing: 15px;

    margin-top: @spacing;
    background: white;
    padding: @spacing 0;

    attorney-license + attorney-license, attorney-degree + attorney-degree {
      margin-top: @spacing;
      border-top: 1px solid #ddd;
      padding-top: @spacing;
    }
  }

  .no-results {
    text-align: center;
    line-height: 40px;
  }
}
