attorney-specialization {
  display: block;
  .clearblock;

  @lightText: #666;

  .preview-selector {
    position: relative;

    @closeSize: 20px;
    @previewsBackground: #f2f2f2;
    @spacing: 5px;
    .previews {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      background: @previewsBackground;
      color: contrast(@previewsBackground);
      border-radius: 3px;
      box-shadow: 0 1px 3px black;
      margin-top: @spacing;
      line-height: 30px;
      color: #666;
      width: 100%;

      .title {
        font-size: 13px;
        padding: 0 @spacing;
        line-height: @closeSize;
        padding-top: @spacing;
        font-weight: bold;
        border-bottom: 1px solid darken(@previewsBackground, 10%);
      }

      .list {
        max-height: 200px;
        overflow: auto;
        width: 100%;
        padding: @spacing 0;
        .clearblock;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: #777;
        font-size: 15px;
        padding: @spacing;

        &:hover {
          color: black;
        }
      }

      .preview {
        padding: 0 10px;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .main-display, .sub-display {
          display: block;
        }

        .sub-display {
          font-size: 12px;
          line-height: 15px;
          opacity: 0.5;
          padding-bottom: 5px;
        }

        &:hover {
          color: black;
          background: white;
        }
      }
    }
  }

  .submit.row {
    padding-top: 0 !important;
    border-top: none !important;

    .button, .remove {
      float: right;
    }

    .remove {
      margin-right: 10px;
      cursor: pointer;
      font-size: 12px;
      color: @lightText;
      line-height: 31px;
      user-select: none;

      &:hover {
        color: black;
      }
    }
  }
}
