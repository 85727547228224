admin-user {
  display: block;

  .row {
    .clearblock;
  }

  .row + .row {
    margin-top: 10px;
  }

  form {
    background: white !important;
    border: 1px solid @border-color;
    border-radius: 3px;
    padding: 10px;

    input.full {
      display: block;
      width: 100%;
    }

    .row {
      line-height: 28px;
    }

    .row-label {
      font-weight: bold;
      clear: both;

      &.spaced {
        padding-top: 28px;
      }
    }
  }

  .submit.row {
    text-align: right;
  }

  .remove-now {
    .button-color(crimson);
  }

  .new-notice {
    @iconWidth: 30px;

    margin-bottom: 10px;
    font-weight: bold;
    position: relative;
    padding-left: @iconWidth + 10px;

    .glyphicon {
      position: absolute;
      left: 0;
      width: @iconWidth;
      text-align: center;
      line-height: 30px;
      font-size: 25px;
    }
  }
}

.admin-user {
  @adminUserHeight: 35px;

  display: block;
  line-height: @adminUserHeight;
  height: @adminUserHeight;
  border: 1px solid @border-color;
  border-radius: 3px;
  background: white;
  .clearblock;

  .item {
    float: left;
    padding: 0 10px;
    height: 100%;

    .button {
      display: block;
      width: 100%;
      top: 2px;
    }

    & > .content {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .name {
    width: 25%;
    text-align: right;
  }

  .email {
    width: 40%;
    height: 100%;
    a {
      display: block;
      height: 100%;
      line-height: @adminUserHeight;
    }
  }

  .created_at {
    width: 15%;
    text-align: center;
  }

  .modify {
    width: 20%;
  }

  .item + .item {
    border-left: 1px solid @border-color;
  }
}

admin-user + admin-user {
  margin-top: 10px;
}
