.application.page .row + .assistant.row {
  margin-top: 20px;
}

.attorney-information {
  position: relative;

  .selection-warning {
    padding: 10px 45px 10px 50px;
    position: relative;
    font-size: 13px;
    line-height: 22px;
    background: #c1c1c1;

    .glyphicon {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 30px;
      text-align: center;
      line-height: 44px;
      color: #ffd600;
      text-shadow: 0 1px 2px black;
      font-size: 17px;
    }
  }

  .saving-info-spinner {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 12px;

    &.visible {
      opacity: 1;
      transition: all 0.3s ease;
    }

    .glyphicon-refresh {
      margin-left: 5px;
    }
  }

  h3 {
    margin-top: 0;
  }

  .title {
    font-weight: bold;
  }

  .small {
    line-height: 15px;
  }

  .save.button {
    float: right;
    margin-right: @padding-large-horizontal;
  }

  .clear-answer.button {
    margin-top: @padding-large-horizontal;
  }

  assistant-information + .clear-answer.button {
    margin-top: @padding-large-vertical;
    float: right;
  }

  div + .clear-answer.button {
    margin: @padding-large-vertical 0 0 0;
  }

  hr {
    clear: both;
  }

  p.title {
    font-weight: bold;
  }

  label {
    display: block;
    cursor: pointer;
    user-select: none;
    margin-bottom: 0;

    &.selected {
      font-weight: bold;
    }

    input {
      float: left;
      width: auto;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }

  form > .row {
    margin: 0 -(@grid-gutter-width / 2);
  }

  textarea {
    min-height: 70px;

    &.big {
      min-height: 100px;
    }
  }

  .row + .row {
    margin-top: 10px;
  }

  input {
    width: 100%;
  }

  .add-social {
    float: right;
  }

  social-link + social-link {
    margin-top: 10px;
  }

  .attorney-assistant {
    .clearblock;

    & > .question {
      margin-bottom: 10px;
    }
  }
}
