user-group {
  display: block;
  float: left;
  line-height: 20px;

  .user-group {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid @border-color;
    background: lighten(@border-color, 10%);
    position: relative;

    .glyphicon {
      display: block;
      float: right;
      line-height: 20px;
      padding: 0 5px 0 10px;
      cursor: pointer;
      color: #777;

      &:hover {
        color: black;
      }
    }

    .wants-to-remove {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 10px;
      z-index: 1;
      background: #f1ffa9;
      box-shadow: 0 1px 3px -1px black;
      width: 250px;
      padding: 10px;

      .name {
        font-weight: bold;
      }

      .answer {
        margin-top: 10px;
        text-align: right;

        .cancel {
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

user-group + user-group {
  margin-left: 10px;
}
