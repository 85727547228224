case-summary {
  display: block;
  clear: both;
}

.case-summary {
  @caseSize: 30px;
  @borderRadius: 3px;

  margin: 0 5px 5px 5px;
  border: 1px solid @border-color;
  border-radius: @borderRadius;
  line-height: @caseSize;
  height: @caseSize * 2;
  background: fadeout(white, 30%);
  position: relative;
  .clearblock;
  padding-left: @idWidth;

  @idWidth: 100px;
  .col {
    float: left;
    height: 100%;
    text-align: center;

    &.member-name {
      position: absolute;
      left: @idWidth;
      top: @caseSize;
      color: #888;
      font-size: 13px;

      .name {
        color: black;
      }
    }

    &.id {
      width: @idWidth;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      opacity: 0.6;
      transition: all 0.4s 0.3s ease;
      line-height: @caseSize * 2;
    }

    &.area-of-law {
      width: 50%;
      text-align: left;
    }

    &.location {
      width: 17%;
      line-height: @caseSize * 2;
    }

    &.created-at {
      width: 15%;
      line-height: @caseSize * 2;
    }

    &.controls {
      width: 18%;
      line-height: @caseSize * 2;

      a {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0.8;

        &:hover {
          text-decoration: none;
          opacity: 1;
        }

        span + span {
          margin-left: 8px;
        }
      }
    }
  }

  &.label-row {
    background: none;
    border: none;
    font-weight: bold;


    .id {
      opacity: 1;
    }

    &:hover {
      background: none;
    }
  }

  &:hover {
    border-color: darken(@border-color, 20%);
    background: white;

    .col {
      opacity: 1;
      transition: all 0s linear;
    }
  }
}
