/* structure defaults */
@footer-height: 130px;
@header-height: 115px;
@nav-height: 35px;
@button-height: 25px;
@mainBorderColor: #dedede;
@pageMaxWidth: @page-width;

@pageSpacing: 20px;

@primaryColor: #dd4814;
@body-bg-color: #FFFFFB;
@body-text: contrast(@body-bg-color);
@inputTextColor: contrast(@input-bg); // bootstrap @input-bg

@header-bg-color: white;
@headerTextColor: contrast(@header-bg-color);
@header-bar-color: @primaryColor;
@header-bar-text-color: contrast(@header-bar-color);

@hr-border: #ddd;
@link-color: #4159EC;
@border-color: #ddd;

@spacing: 10px;
@radius: 3px;

@main-line-height: 20px;
@primary-font-family: Lato, proxima-nova, 'Helvetica Neue', Arial, sans-serif;
@primary-font: 13px/@main-line-height @primary-font-family;
@header-font: 13px/@main-line-height @primary-font-family;

@button-background: #3167CE;

// List of profile state colors
@defaultStateColor: #7CAAFF;

outlet, .section-chooser {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.no-js-message {
  display: none;
}
html.no-js .no-js-message {
  display: block;
  max-width: @page-width;
  box-sizing: border-box;
  text-align: center;
  padding: 50px 0;
  font-weight: bold;
  margin: 0 auto;
  font-size: 20px;

  .glyphicon {
    margin-right: 5px;
  }
}

.logged-in {
  background: #666;
  color: white;
  border-color: #666;
}

.never-logged-in {
  background: #fff;
  color: black;
  border-color: #fff;
}

.profile-not-started {
  background: #9E9E9E;
  color: white;
  border-color: #9E9E9E;
}

.profile-started {
  background: #7CAAFF;
  color: white;
  border-color: #7CAAFF;
}

.profile-incomplete {
  background: @defaultStateColor;
  color: white;
  border-color: @defaultStateColor;
}

.profile-incomplete-communicated-to-attorney {
  background: @defaultStateColor;
  color: white;
  border-color: @defaultStateColor;
}

.updated-after-approval {
  background: #3c7c80;
  color: white;
  border-color: #3c7c80;
}

.profile-completed-by-attorney-ready-for-review {
  background: #5BACBF;
  color: white;
  border-color: #5BACBF;
}

.profile-complete-ready-for-executive-review {
  background: #39906D;
  color: white;
  border-color: #39906D;
}

.does-not-meet-requirements-permanent, .do-not-contact-per-atty-request {
  background: #656565;
  color: white;
  border-color: #656565;
}

.does-not-meet-requirements-temporary {
  background: #687A9C;
  color: white;
  border-color: #687A9C;
}
.executive-profile-approved {
  background: #7bc16a;
  color: white;
  border-color: #7bc16a;
}

.executive-profile-issue-noted {
  background: #C649E4;
  color: white;
  border-color: #C649E4;
}

.executive-profile-declined {
  background: black;
  color: white;
  border-color: black;
}


body {
  background: @body-bg-color;
}

.spaced-row {
  .clearblock;

  &.submit {
    text-align: right;
  }
}

.spaced-row + .spaced-row {
  display: block;
  margin-top: 10px;
}

input, select, textarea {
  background: @input-bg;
  color: @inputTextColor;
}

.form-control {
  transition: none;

  &:focus {
    box-shadow: none;
    border-color: black;
    transition: none;
  }
}

::-webkit-input-placeholder {
   color: mix(@inputTextColor, @input-bg, 30%);
}

:-moz-placeholder { /* Firefox 18- */
   color: mix(@inputTextColor, @input-bg, 30%);
}

::-moz-placeholder {  /* Firefox 19+ */
   color: mix(@inputTextColor, @input-bg, 30%);
}

:-ms-input-placeholder {
   color: mix(@inputTextColor, @input-bg, 30%);
}

form > .row {
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.supported-browser, router {
  height: 100%;
  min-height: 100%;
}

input[type="text"], input[type="password"], select, textarea {
  @line-height: 28px;
  line-height: @line-height - 2px;
  outline-style: none;
  display: inline-block;
  height: @line-height;
  border: 1px solid @border-color;
  border-radius: @radius;
  outline-style: none;
  padding: 0 5px;

  &.form-control {
    padding: 0 5px;
    display: inline-block;
    height: @line-height;
  }

  &:hover {
    border-color: darken(@border-color, 15%);
  }
  &:focus {
    border-color: black;
  }
}
select, select.form-control {
  padding: 0;
}

::selection {
  background: #222;
  color: white;
  }
::-moz-selection {
  background: #222;
  color: white;
}

.request.button {
  &.active {
    @busyColor: #888;
    background: @busyColor;
    border-color: darken(@busyColor, 10%);
  }
}

.button {
  outline-style: none;
  user-select: none;

  .glyphicon {
    line-height: 27px;
  }

  .glyphicon + span, span + .glyphicon {
    padding-left: 5px;
  }
}
