@startColor: rgb(255,20,61);
@finishColor: rgb(134,13,36);

.warning {
  @glyphWidth: 50px;

  background: @startColor;
  color: white;
  padding: 10px;
  margin: 10px 0;
  position: relative;
  padding-left: @glyphWidth;
  animation: breathing 3s linear 4 normal;

  transition-delay: 1s !important;

  .glyphicon {
    position: absolute;
    left: 0;
    width: @glyphWidth;
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
  }

  em {
    color: white;
    font-weight: bold;
  }
}

@keyframes breathing {
  0% {
    background: @startColor;
  }

  50% {
    background: @finishColor;
  }

  100% {
    background: @startColor;
  }
}