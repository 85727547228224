county {
  .clearblock;

  .county {
    display: block;
  }

  .col, .delete-col {
    float: left;
  }

  .col {
    width: 45%;
    box-sizing: border-box;
  }

  .col + .col {
    padding-left: 10px;
  }

  .delete-col {
    width: 10%;
    text-align: center;

    .destroy {
      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }
}

county + county {
  margin-top: 10px;
}
