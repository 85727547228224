case-list {
  display: block;
}

.case-list {
  @numCaseSize: 40px;
  @caseLabelColor: white;
  @borderRadius: 3px;

  border: 1px solid @border-color;
  border-radius: @borderRadius;

  .no-cases {
    line-height: @numCaseSize;
    padding: 0 10px;
  }

  & > .title {
    font-size: 15px;
    background: @caseLabelColor;
    border-radius: @borderRadius @borderRadius 0 0;
    border-bottom: 1px solid @border-color;
    line-height: @numCaseSize;
    .clearblock;

    .title-label {
      font-weight: bold;
    }

    .num-cases {
      width: @numCaseSize;
      height: @numCaseSize;
      line-height: @numCaseSize;
      text-align: center;
      display: inline-block;
      border-radius: @borderRadius @borderRadius 0 0;
      font-weight: normal;
      margin-right: 10px;
      font-weight: bold;
      border-right: 1px solid @border-color;
    }

    .controls {
      float: right;

      .range-display {
        float: right;
        line-height: @numCaseSize;
        margin-right: 20px;

        .range-label, .total {
          font-weight: bold;
        }
      }

      .pages {
        float: right;
        clear: both;
        .clearblock;
        user-select: none;
        position: relative;
        height: @numCaseSize;

        @hoverPageLinkColor: black;
        @pageLinkColor: lighten(@hoverPageLinkColor, 44%);

        .page {
          float: left;
          color: @pageLinkColor;
          width: 25px;
          text-align: center;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          line-height: @numCaseSize;

          &.next {
            border-left: 1px solid @border-color;
            margin-left: 5px;
          }

          &.previous {
            border-right: 1px solid @border-color;
            margin-right: 5px;
          }

          &.clickable {
            cursor: pointer;

            &.outOfBounds {
              display: none;
            }

            &:hover {
              color: @hoverPageLinkColor;
            }

            &:active {
              color: @pageLinkColor;
            }

            &.active {
              color: @hoverPageLinkColor;
              background: white;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  & > .content {
    .clearblock;
    background: darken(@caseLabelColor, 6%);
    padding-top: 5px;
  }
}
