attorney-degree {
  display: block;
  .clearblock;

  @lightText: #666;

  .submit.row {
    padding-top: 0 !important;
    border-top: none !important;

    .button, .remove {
      float: right;
    }

    .remove {
      margin-right: 10px;
      cursor: pointer;
      font-size: 12px;
      color: @lightText;
      line-height: 31px;
      user-select: none;

      &:hover {
        color: black;
      }
    }
  }
}
