admin-attorney-display {
  .clearblock;
  clear: both;

  @rowBackground: white;
  @rowForeground: contrast(@rowBackground);
  @contentSpacing: 5px;

  .loading-spinner {
    display: none;
  }

  .firm-display {
    position: relative;

    .all-attorneys {
      padding-top: 5px;
      border-top: 1px solid rgba(0,0,0,0.2);
      margin-top: 5px;
      display: block;
      font-size: 12px;
      color: #777;
      white-space: nowrap;

      &:hover {
        color: black;
        text-decoration: none;
      }
    }

    .firm-name {
      cursor: pointer;
      user-select: none;
      padding-right: 20px;
      position: relative;
      white-space: nowrap;

      .glyphicon {
        position: absolute;
        top: 2px;
        right: 0;
        color: #777;
      }

      &:hover .glyphicon {
        color: black;
      }
    }

    @spacing: 8px;
    .firm-members {
      position: absolute;
      top: -@spacing;
      left: -@spacing;
      display: none;
      z-index: 1;
      background: #FFFED3;
      box-shadow: 0 1px 3px black;
      padding: @spacing 20px @spacing @spacing;

      .close {
        position: absolute;
        top: @spacing;
        right: 5px;
        font-size: 13px;
      }

      .firm-name .glyphicon {
        color: black;
      }

      & > .list {
        margin-top: 5px;
        .clearblock;

        .attorney {
          display: block;
          clear: both;
          cursor: pointer;
          float: left;
        }
      }
    }

    &.showFirmAttorneys {
      .firm-members {
        display: block;
      }
    }
  }

  .admin-attorney-display {
    padding: 5px 0px;
    background: @rowBackground;
    color: @rowForeground;
    border-radius: 3px;
    border: 1px solid #ddd;

    &.isExpanded {
      padding-bottom: 0;
    }

    &:hover, &.isExpanded {
      border-color: #aaa;
    }
  }

  .expanded-view {
    clear: both;
    display: block;
    margin-top: @contentSpacing;
    .clearblock;

    .summary, .credential-manager {
      box-sizing: border-box;
      .clearblock;
    }

    .credential-manager {
      height: 500px;
    }

    &.hasCredentialReviewData .summary {
      border-bottom: 1px solid #ddd;
    }

    .summary {
      font-size: 12px;
      padding: 5px;
      position: relative;

      .profile-link {
        position: absolute;
        right: 6px;
        top: 5px;
        font-weight: normal;
      }
    }

    .summary-row {
      width: 100%;
      .clearblock;

      &.cases {
        margin-top: 5px;
      }

      .summary-label, .summary-value {
        box-sizing: border-box;
        float: left;
      }

      .summary-label {
        text-align: right;
        padding-right: 5px;
        width: 20%;
        font-weight: bold;
      }
      .summary-value {
        padding-left: 5px;
      }

      .case-count {
        padding: 3px 5px;
        margin-right: 3px;
        background: #dedede;
        border-radius: 3px;
      }
    }
  }

  .content {
    display: block;
    .clearblock;
  }

  .col {
    float: left;
    line-height: 25px;

    @lightTextColor: mix(@rowBackground, @rowForeground, 60%);

    .display {
      font-size: 12px;
      line-height: 15px !important;
      display: block;
      position: relative;
    }

    .message {
      @messageHeight: 11px;
      white-space: nowrap;
      font-size: @messageHeight;
      line-height: @messageHeight;
      color: @lightTextColor;
      box-sizing: border-box;
    }

    & > .content {
      padding: 0 @contentSpacing;
      height: 100%;
    }

    &.name {
      width: 33%;
      position: relative;

      .dl-state {
        padding-left: @contentSpacing;
        font-size: 12px;
        line-height: 12px;
        color: @lightTextColor;
      }
    }

    &.currentState {
      width: 35%;
      height: 100%;
      display: table;
      text-align: center;
      padding: 0 5px;
      border-radius: 3px;
      font-size: 12px;
      line-height: 15px;

      & > .content {
        display: table-cell;
        vertical-align: middle;
      }
    }

    &.nextAction {
      line-height: 18px;
      width: 17%;
      font-size: 12px;

      .content {
        padding-right: 0;
      }
    }

    &.toggle-display {
      line-height: 18px;
      width: 15%;
      user-select: none;

      @background: #efefef;
      @color: contrast(@background);
      @lightColor: mix(@background, @color);

      .glyphicon {
        display: block;
        width: 100%;
        height: 37px;
        font-size: 20px;
        line-height: 37px;
        text-align: center;
        color: @lightColor;
        cursor: pointer;
        border: 1px solid #ddd;
        border-radius: 3px;
        background: #efefef;

        &:hover {
          color: @color;
          background: darken(@background, 10%);
        }

        &:active, &.isExpanded {
          color: @lightColor;
          box-shadow: 0 1px 3px -1px black inset;
        }

        &.isExpanded {
          background: darken(@background, 10%);
        }
      }
    }
  }
}

admin-attorney-display + admin-attorney-display {
  margin-top: 10px;
}
