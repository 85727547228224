.benefits-plans {
  display: block;
  .clearblock;

  h3 {
    margin-top: 0;
  }

  .well {
    .clearblock;
  }
}
