.basic-information {
  h3 {
    margin-top: 0;
  }

  .profile-image {
    width: 64px + 1px;
    height: 64px + 1px;
    background: rgba(0,0,0,0.1);
    float: left;
    position: relative;
    margin: 0 @padding-large-horizontal 0 0;
    padding: 0;

    img {
      position: absolute;
      top: 0;
      max-width: 100%;
      max-height: 100%;
      border-radius: 3px;
    }

    .crop-button {
      @cropButtonSize: 25px;

      position: absolute;
      bottom: 5px;
      right: 5px;
      width: @cropButtonSize;
      height: @cropButtonSize;
      background: white;
      border-radius: 3px;
      border: 1px solid black;

      &:active {
        box-shadow: 0 1px 3px -1px black inset;
        background: #f2f2f2;
      }

      .glyphicon {
        display: block;
        width: 100%;
        height: 100%;
        line-height: @cropButtonSize - 2px;
        text-align: center;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .file-upload-button {
    display: block;
  }

  .file-uploader {
    @fileUploaderPadding: 10px;

    padding: @fileUploaderPadding;
    border: 1px solid #ddd;
    float: left;
    border-radius: @border-radius-small;
    background: white;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    button {
      display: block;
      min-width: 100%;
      margin-top: @fileUploaderPadding;
    }
  }

  .file-label {
    color: mix(@well-bg, @text-color);
    font-size: 12px;

    & + span {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .image-cropper {
    img {
      max-width: 100%;
    }

    .crop.button {
      float: right;
      margin-top: 10px;
    }
  }

  .clear-answer.button {
    margin-left: @padding-large-vertical;
  }

  div + .clear-answer.button {
    margin: @padding-large-vertical 0 0 0;
    float: right;
  }

  .saving.spinner {
    position: relative;
    top: 4px;
    margin-right: 10px;
    font-size: 15px;
  }
}
