.admin-users {
  .create.button {
    margin-bottom: 10px;
    float: right;
  }

  .users-list {
    clear: both;
  }
}
