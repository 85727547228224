.dashboard-welcome {
  & > .content {
    .name {
      font-weight: bold;
    }

    ul {
      margin-top: @spacing;

      li {
        list-style-type: disc;
        margin-left: 2em;
        font-weight: bold;
      }

      li + li {
        margin-top: 5px;
      }
    }
  }
}
