firm {
  @borderColor: #ddd;

  padding: (@grid-gutter-width / 2) 0;
  border-radius: 3px;
  background: white;
  border: 1px solid @borderColor;
  position: relative;
  .clearblock;
  line-height: 40px;

  &:hover {
    border-color: darken(@borderColor, 15%);
  }

  .select-firm {
    float: right;
  }

  .firm-mutate-row {
    .clearblock;

    .button {
      float: right;
      margin-top: 10px;
    }
  }

  p.question {
    font-weight: bold;
    margin: 20px 0 0;
  }

  .counties-display, p.question {
    line-height: 27px
  }

  .firm-label {
    font-weight: bold;
    width: 25%;
    float: left;
  }

  .button + .button {
    margin-right: 5px;
  }

  .offices {
    .clearblock;
    clear: both;
    margin-top: 10px;
  }

  .firm {
    @borderColor: #ddd;

    padding: 10px;
    border-radius: 3px;
    background: white;
    border: 1px solid @borderColor;
    position: relative;
    .clearblock;
    line-height: 40px;

    &:hover {
      border-color: darken(@borderColor, 15%);
    }

    .select-firm {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .firm-label {
      font-weight: bold;
      width: 25%;
      float: left;
    }
  }
}

.row + firm, firm + firm {
  margin-top: 10px;
}
