.register.page {
  @spacing: 15px;

  em {
    font-weight: bold;
    color: @primaryColor;
  }

  .reset-notice {
    margin: 15px 0;
  }
}
