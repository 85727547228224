.application.page {
  .inner-row {
    .clearblock;
    margin: @padding-large-vertical 0;
  }

  .masquerade-user {
    font-size: 20px;
    .clearblock;
    margin-bottom: 20px;
    @height: 50px;

    .fullName {
      @background: #dedede;

      float: right;
      font-weight: bold;
      padding: 0 10px;
      line-height: @height;
      background: @background;
      border: 1px solid darken(@background, 10%);
      border-radius: 3px;
    }

    .title {
      float: left;
      line-height: @height;
      margin-left: 25%;
      font-weight: bold;
    }
  }

  form {
    @line-height: 27px;

    line-height: @line-height;

    .titles {
      color: mix(@body-bg-color, @body-text);
    }

    .row + .row {
      margin-top: 10px;
    }

    .row-label {
      font-weight: bold;
      clear: both;

      &.spaced {
        padding-top: @line-height;
      }
    }

    input[type="text"], select, textarea {
      display: block;
      width: 100%;
    }

    .submit.row {
      clear: both;
      padding-top: 20px;
      margin: @line-height-computed 0 0 0;
      text-align: right;
      border-top: 1px solid #ccc;
    }

    .button {
      outline-style: none;
    }

    .no.button {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }

    hr {
      border-color: #ccc;
    }
  }

  .section-chooser {
    .clearblock;
    float: right;
    margin-bottom: 10px;
    position: relative;
    min-height: 30px;

    .section-title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      font-weight: bold;
      line-height: 31px;
      font-size: 20px;
      color: @primaryColor;
    }

    .prev.button {
      float: left;
      position: relative;
      z-index: 1;
    }

    .next.button {
      float: right;
      opacity: 0.6;
    }
    &.sectionComplete {
      .next.button {
        opacity: 1;
      }
    }
  }

  timeblock {
    input, select, textarea {
      display: inline;
      width: auto;
    }
  }

  outlet {
    .clearblock;
    display: block;
  }

  @media (max-width: @screen-md) {
    .masquerade-user {
      .fullName {
        float: none;
      }

      .title {
        float: none;
        margin-left: 0;
      }
    }

    outlet {
      padding: 0 !important;
    }

    .section-chooser {
      float: none;
      padding: 0;
    }

    .application-menu {
      margin-bottom: 20px;
      margin-top: 20px;
      padding-right: 0;

      .app-section {
        text-align: left;
      }
    }

    form {
      .row-label {
        padding-top: 0px;
      }

      & > .row {
        .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
          padding: 0;
        }

        .row {
          margin: 0;
        }
      }
    }
  }
}
