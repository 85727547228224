.attorney-hourly-rates {
  display: block;
  .clearblock;

  & > h3 {
    margin-top: 0;
  }

  .money {
    @moneyPadding: 15px;
    position: relative;

    .dollar-sign {
      position: absolute;
      left: 16px;
      top: 0;
      width: @moneyPadding - 1px;
      line-height: 28px;
      text-align: center;
      color: #999;
    }

    input {
      padding-left: @moneyPadding;
    }
  }
}
