.input .hovertip {
  position: absolute;
  right: 5px;
  top: 0;
}

@tooltipBackground: #C3CABF;
@tooltipText: contrast(@tooltipBackground);
@toolTipWidth: 200px;

.hovertip-color(@backgroundColor) {
  .hovertip-content {
    background: @backgroundColor;

    &:after {
      border-top-color: @backgroundColor;
    }

    p {
      color: mix(@tooltipText, @backgroundColor, 70%);
    }
  }
}

.hovertip {
  position: relative;
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  border-radius: 8px;
  padding: 1px;

  &:hover {
    .hovertip-content {
      display: block;
      height: auto;
      padding: 10px;
      opacity: 1;
      transition: opacity 0.3s ease, bottom 0.3s ease;
      overflow: visible;
      bottom: 100%;

      img {
        margin-top: 5px;
      }
    }
  }

  .hovertip-content {
    opacity: 0;
    overflow: hidden;
    height: 0;
    position: absolute;
    color: @tooltipText;
    bottom: 130%;
    z-index: 100;
    border-radius: 3px;
    margin: 0 0 7px 7px;
    box-sizing: border-box;
    transform: translateX(-50%);
    width: @toolTipWidth;
    font-size: 13px;
    line-height: 20px;
    .box-shadow(0 1px 3px 0px black);
    font: 13px arial;
    z-index: 1000;

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0);
      border-width: 8px;
      margin-left: -9px;
    }

    p {
      margin: 0;

      &.note {
        font-size: 12px;
        line-height: 17px;
      }
    }

    p + p {
      margin-top: 10px;
    }
  }

  .hovertip-color(@tooltipBackground);
}
