.case-reports {
  display: block;
  margin-top: 20px;

  form {
    .clearblock;
  }

  canvas {
    width: 100%;
    min-height: 400px;
    background: white;
    border-radius: 3px;
    border: 1px solid #ddd;
  }

  .export-dialog {
    clear: both;
    margin-top: 10px;
  }

  .generating-report {
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;

    .glyphicon {
      margin-right: 5px;
    }
  }

  .report-list {
    & > label {
      display: block;
      line-height: 20px;
      padding-bottom: 3px;
    }

    label + label {
      padding-top: 3px;
    }
  }

  .export-tools {
    padding-top: 10px;
    text-align: right;

    a + a {
      margin-left: 10px;
    }
  }
}
