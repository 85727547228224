.index.page {
  @spacing: 20px;

  p:last-child {
    margin-bottom: 0;
  }

  img.right {
    float: right;
    border: 3px solid #4C4C4C;
    margin-left: @spacing;
    margin-bottom: @spacing;
    box-shadow: 0 1px 5px 0px black inset;
    background: rgba(0, 0, 0, 0.1);
  }

  #top-section {
    @topBackground: #e8e8e8;
    background: @topBackground;
    border-bottom: 1px solid darken(@topBackground, 10%);
    width: 100%;
    box-sizing: border-box;
    .clearblock;
  }

  .index-content {
    max-width: @page-width;
    width: @page-width;
    margin: 0 auto;
    padding: @spacing;
  }

  .bottom.col {
    box-sizing: border-box;
    & > .content {
      padding-top: @spacing;
    }
  }

  .methodology {
    margin-bottom: @spacing;
    font-size: 20px;

    span {
      color: @primaryColor;
      font-weight: bold;
    }
  }

  .col-group {
    .clearblock;
    clear: both;
  }

  .plan-row {
    .clearblock;
    clear: both;
    margin-top: @spacing;

    ul {
      margin-bottom: 0;
    }
  }

  .plan {
    float: left;
    width: 50%;
    box-sizing: border-box;

    &:first-child {
      padding-right: @spacing;
    }

    .name {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  em {
    font-weight: bold;
    color: @primaryColor;
  }
}
