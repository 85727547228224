percentage-of-practice {
  display: block;
  .clearblock;
  float: left;
  width: 50%;

  .percentage-of-practice {
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    display: none;

    &.visible {
      display: block;
      margin-right: 10px;
      padding: 0 5px;
      margin-bottom: 10px;
      line-height: 30px;
    }

    &.hasValue {
      border-color: @primaryColor;
      transition: all 1.3s ease;
    }

    @labelWidth: 60%;
    .percentage-label {
      display: block;
      width: @labelWidth;
      text-align: right;
      float: left;
    }

    .percent-container {
      display: block;
      position: relative;
      width: 100% - @labelWidth;
      float: left;

      @percentWidth: 15px;
      @inputHeight: 25px;

      input {
        position: relative;
        width: 100%;
        margin-left: 5px;
        padding-right: @percentWidth;
        line-height: @inputHeight;
        height: @inputHeight;
      }

      .percent {
        position: absolute;
        right: 0;
        top: 4px;
        display: block;
        color: #999;
        font-size: 12px;
        width: @percentWidth;
        line-height: @inputHeight;
        text-align: center;
      }
    }
  }
}
