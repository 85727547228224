.case-profile {
  font-family: arial;
  max-width: 940px;

  .label {
    color: black;
    display: block;
    font-size: 100%;
    font-weight: normal;
    line-height: 25px;
    vertical-align: middle;
    border-radius: none;
    padding: 0;
  }

  .row {
    margin: 0;
  }

  .caseId {
    color: @primaryColor;
    font-size: 15px;
  }

  @panelsBorderColor: @border-color;
  @panelsColor: lighten(@panelsBorderColor, 10%);
  @labelWidth: 30%;

  & > .content > p {
    font-weight: bold;
    font-size: 20px;

    &.note {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .expandable .icon {
    display: none;
  }

  & > div {
    .box-sizing(border-box);
  }

  .basic, .preferences, .areaOfLaw {
    width: 100%;
    float: left;
    clear: both;
    background: @panelsColor;
    border: 1px solid @panelsBorderColor;
    border-radius: 3px;
    box-sizing: border-box;

    @titleHeight: 50px;
    & > .title {
      font-size: 20px;
      font-weight: bold;
      line-height: @titleHeight - 1px;
      height: @titleHeight;
      padding: 0 @spacing;
      border-bottom: 1px solid @border-color;
      background: rgb(234, 233, 245);
      box-sizing: border-box;
    }

    & > .content, & > * > .content {
      padding: @spacing;
      .clearblock;
    }
  }

  .attr, .preference {
    padding: 5px;
    background: white;
    border: 1px solid @border-color;
    border-radius: 3px;
  }

  .preferences {
    float: left;
    margin-top: @spacing;
  }

  .preference {
    width: auto;
    margin-right: 5px;
    margin-bottom: 5px;
    float: left;
  }

  .preference + .preference {
    padding-left: 5px;
  }

  .label, .value {
    float: left;
    line-height: 25px;
    box-sizing: border-box;
  }

  .numbers.row {
    margin: 10px 0;

    .number + .number {
      margin-top: 5px;
    }
  }

  .label {
    width: @labelWidth;
    text-align: right;
    padding-right: 10px;
  }

  .value {
    width: 100% - @labelWidth;
  }

  .name.value {
    font-weight: bold;
  }

  .areaOfLaw {
    margin: @spacing 0;
    .expandable-color(@panelsColor);

    & > .content {
      height: 500px;
      overflow-y: auto;
      box-sizing: border-box;
    }
  }

  .expandable + .option {
    margin-top: @spacing;
  }

  .content > .icon, .icon + h2 {
    color: @primaryColor;
  }

  .icon + h2 + p {
    margin-top: 10px !important;
  }

  .expandable {
    p {
      margin: 0;
    }

    li + li {
      margin-top: 5px;
    }

    p + p, em + p, p + em, ul + p, p + ul, ol + p, p + ol, em + ul, ul + em, em + ol, ol + em {
      display: block;
      margin-top: @spacing / 2;
    }

    h2 {
      margin: 20px 0 10px;
    }
  }
}
