.attorney-background {
  display: block;
  .clearblock;

  & > h3 {
    margin-top: 0;
  }

  .spaced-row {
    position: relative;

    .busy-notification {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      color: #777;
      visibility: none;
      opacity: 0;
      transition: all 0.3s ease;

      &.active {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease;
      }

      .glyphicon {
        margin-left: 5px;
        color: black;
      }
    }
  }

  .questions {
    max-height: 500px;
    overflow: auto;
    background: white;
    padding: 20px;
    border-radius: 3px 0 0 3px;

    .background-doc-notice {
      font-weight: bold;
      margin-top: 20px;
    }

    required-document {
      margin-top: 20px;

      .required-document {
        margin: 0;
      }
    }
  }

  attorney-background-question + attorney-background-question {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
  }
}
