// NOTE: Paths for @import directives are specified in gulpfile.js in the 'build-css' task

// Footwork animations
@import (less) "../bower_components/footwork/dist/footwork.css";

// Twitter Bootstrap (and its included icon font kit)
@import "../bower_components/bootstrap/less/bootstrap.less";
@icon-font-path: "../bower_components/bootstrap/dist/fonts/";

// jquery plugins
@import (less) "../bower_components/datetimepicker/jquery.datetimepicker.css";
@import (less) "../bower_components/cropper/dist/cropper.css";

// file icons
@import (less) "include/file-icons/file-icons.css";

@page-width: 940px;
@navbar-height: 40px;
@footer-height: 100px;

html {
  overflow-y: scroll;
}

@import "include/mixins.less";
@import "include/defaults.less";
@import "include/force-footer-down.less";
@import "include/spinner.less";
@import "include/button-ripple.less";
@import "include/hovertip.less";
@import "include/validator.less";

// Styles for primary areas of the application
@import "module/header.less";
@import "module/nav.less";
@import "module/main.less";
@import "module/card.less";
@import "module/footer.less";
@import "module/loading-display.less";
@import "module/row-selector-input.less";
@import "module/application-menu.less";
@import "module/expandable.less";
@import "module/page.less";
@import "module/register-page.less";

// Component style inclusions
@import "benefits-language/benefits-language.less";
@import "questionnaire/questionnaire.less";
@import "case-profile/case-profile.less";
@import "case-page/case-page.less";
@import "case-summary/case-summary.less";
@import "cases-page/cases-page.less";
@import "case-list/case-list.less";
@import "contact-us-page/contact-us-page.less";
@import "notifications/notifications.less";
@import "notification/notification.less";
@import "change-password-page/change-password-page.less";
@import "user-menu/user-menu.less";
@import "user-login/user-login.less";
@import "register/register.less";
@import "error/error.less";
@import "timeblock/timeblock.less";
@import "password-reset-request/password-reset-request.less";
@import "password-reset-form/password-reset-form.less";
@import "application-page/application-page.less";
@import "application-page/law-area/law-area.less";
@import "application-page/language/language.less";
@import "application-page/office/office.less";
@import "application-page/county/county.less";
@import "application-page/application-welcome/application-welcome.less";
@import "case-reports/case-reports.less";
@import "chart/chart.less";

@import "application-page/basic-information/basic-information.less";

@import "application-page/firm-information/firm-information.less";
@import "application-page/firm-information/firm-languages/firm-languages.less";
@import "application-page/firm-information/firm-offices/firm-offices.less";
@import "application-page/firm-information/firm-attorneys/firm-attorneys.less";
@import "application-page/firm-information/firm-attorney/firm-attorney.less";
@import "application-page/firm-information/firm/firm.less";

@import "application-page/attorney-information/attorney-information.less";
@import "application-page/attorney-information/preferred-area/preferred-area.less";
@import "application-page/attorney-information/percentage-of-practice/percentage-of-practice.less";
@import "application-page/attorney-information/attorney-languages/attorney-languages.less";
@import "application-page/attorney-information/attorney-license/attorney-license.less";
@import "application-page/attorney-information/attorney-degree/attorney-degree.less";
@import "application-page/attorney-information/attorney-specialization/attorney-specialization.less";
@import "application-page/attorney-information/social-link/social-link.less";
@import "application-page/attorney-information/attorney-offices/attorney-offices.less";
@import "application-page/attorney-information/attorney-areas-of-law/attorney-areas-of-law.less";
@import "application-page/attorney-information/attorney-hourly-rates/attorney-hourly-rates.less";
@import "application-page/attorney-information/attorney-background/attorney-background.less";
@import "application-page/attorney-information/attorney-background/attorney-background-question/attorney-background-question.less";
@import "application-page/attorney-information/attorney-education-and-licensing/attorney-education-and-licensing.less";
@import "application-page/attorney-information/assistant-information/assistant-information.less";

@import "application-page/documents/required-document/required-document.less";
@import "application-page/documents/uploaded-document/uploaded-document.less";
@import "application-page/documents/benefits-plans/benefits-plans.less";
@import "application-page/documents/network-provider-agreement/network-provider-agreement.less";
@import "application-page/documents/professional-liability-insurance/professional-liability-insurance.less";
@import "application-page/documents/questionnaire-certification/questionnaire-certification.less";
@import "application-page/documents/specific-addendums/specific-addendums.less";

@import "application-page/finish/finish.less";
@import "application-page/finish-warning/finish-warning.less";
@import "application-page/thank-you/thank-you.less";
@import "application-page/sorry-temporary/sorry-temporary.less";
@import "application-page/sorry-permanent/sorry-permanent.less";

@import "dashboard-page/dashboard-page.less";
@import "dashboard-welcome/dashboard-welcome.less";
@import "dashboard-cases/dashboard-cases.less";
@import "dashboard-attorney-profile/dashboard-attorney-profile.less";
@import "profile-page/profile-page.less";
@import "index-page/index-page.less";

@import "admin/admin-page/admin-page.less";
@import "admin/admin-user/admin-user.less";
@import "admin/admin-users/admin-users.less";
@import "admin/user-group/user-group.less";
@import "admin/user-actions/user-actions.less";
@import "admin/user-action/user-action.less";
@import "admin/admin-config/admin-config.less";
@import "admin/admin-welcome/admin-welcome.less";
@import "admin/admin-case-list/admin-case-list.less";
@import "admin/admin-case-summary/admin-case-summary.less";
@import "admin/admin-attorney-search/admin-attorney-search.less";
@import "admin/admin-attorney-display/admin-attorney-display.less";
@import "admin/admin-attorney-credential-manager/admin-attorney-credential-manager.less";
@import "admin/admin-attorney-credential-file/admin-attorney-credential-file.less";
@import "admin/admin-attorney-credential-status/admin-attorney-credential-status.less";
@import "admin/admin-attorney-credential-status-note/admin-attorney-credential-status-note.less";
