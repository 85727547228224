/**
 * This LESS file styles the multi-row input control such as the ones used for areas of law and languages
 */

.row-select-summary {
  clear: both;
  .clearblock;

  .question {
    font-weight: bold;
    line-height: 20px;
    padding-bottom: 5px;
  }

  .chosen-areas {
    font-size: 12px;
    line-height: 15px;
    margin-top: 10px;
    padding-top: 15px;
    height: 595px;
    overflow: auto;
    .clearblock;

    .law-area, .language {
      position: relative;
      cursor: pointer;
      opacity: 0.6;
      padding-left: 15px;
      user-select: none;
      transition: none;

      &:hover {
        opacity: 1;
        transition: none;
      }

      .glyphicon {
        position: absolute;
        display: block;
        left: 0;
      }
    }

    .area-of-law + .area-of-law {
      margin-top: 3px;
    }
  }
}

.row-select-container {
  position: relative;
  padding-left: 0;
  .clearblock;

  @iconWidth: 28px;

  .inner-label {
    @labelColor: white;
    @lineHeight: 25px;

    position: relative;
    margin-top: 2px;
    display: block;
    min-width: 100%;
    float: left;
    transition: none;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0 5px;
    border-radius: 3px;
    line-height: @lineHeight;
    user-select: none;
    padding-left: 5px;
    transition: padding 0.3s ease;
    overflow: hidden;

    &:hover {
      background: #eee;
    }

    &:active {
      background: @labelColor;
    }

    .glyphicon {
      position: absolute;
      left: -20px;
      font-size: 20px;
      float: left;
      color: green;
      opacity: 0;
      line-height: @lineHeight;

      width: 0;
      overflow: hidden;
      padding-right: 0px;
      transform: scale(0);
      transition: transform 0.3s ease, width 0.3s ease, left 0.3s ease, opacity 0.3s linear;
    }

    &.active {
      background: @labelColor;
      color: contrast(@labelColor);
      padding-left: @iconWidth;
      transition: padding 0.3s ease;

      .glyphicon {
        opacity: 1;
        width: @iconWidth;
        left: 3px;
        transform: scale(1);
        transition: transform 0.3s ease, width 0.3s ease, left 0.3s ease, opacity 0.3s linear;
      }
    }
  }

  .search-container {
    position: relative;
    margin-bottom: 5px;
    clear: both;
    .clearblock;

    .glyphicon {
      position: absolute;
      line-height: 25px;
      color: #ccc;
      cursor: pointer;
    }

    .glyphicon.search {
      left: 10px;
    }

    .glyphicon.clear {
      right: 10px;
    }

    input {
      padding-left: 30px;
      display: block;
      width: 100%;
      font-weight: normal;
    }
  }

  & > .content {
    height: 400px;
    overflow: auto;
    background: #ddd;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 3px 0 0 3px;
    .clearblock;

    &.large {
      height: 690px;
    }

    hr {
      @spacing: 10px;
      display: block;
      clear: both;
      width: 100%;
      padding-top: @spacing;
      margin-bottom: @spacing;
      border: none;
      border-bottom: 1px solid #aaa;
    }
  }

  .loading-more-display {
    clear: both;
    text-align: center;
    font-size: 30px;
    padding: 10px 0;
  }
}

@media (max-width: @screen-md) {
  .row-select-summary {
    margin-bottom: 10px;
  }
}
