outlet[name="main"] {
  max-width: 100%;
  overflow: hidden;
}

.loading-display {
  font-size: 20px;
  text-align: center;
  display: block;
  color: #999;
  max-width: 66%;
  margin: 50px auto;

  .loading-message {
    margin-bottom: 10px;
  }
  .glyphicon-refresh {
    margin-right: 10px;
  }
}
