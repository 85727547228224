admin-attorney-credential-file {
  .clearblock;
  clear: both;

  label {
    font-weight: normal;
    margin-bottom: 0;
  }

  uploaded-document {
    border: none;
    margin: 0 0 0 22px;
    padding-top: 0;
    padding-bottom: 5px;
    background: transparent;

    .note {
      background: white;
    }
  }
}
