social-link {
  display: block;
  .clearblock;

  @lightText: #666;

  form {
    .clearblock;
    display: block;

    .default-linkUrl {
      padding-right: 0;
    }

    .remove {
      cursor: pointer;
      color: #666;
      line-height: 28px;

      &:hover {
        color: black;
      }
      &:active {
        color: #444;
      }
    }
  }
}
