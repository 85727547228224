.attorney-offices {
  display: block;
  .clearblock;

  & > h3 {
    margin-top: 0;
  }

  & > .well {
    .clearblock;
  }

  .offices {
    background: white;
    padding: 10px 0;
    border-radius: 3px;
  }

  .add-office {
    margin-top: 20px;
    float: right;
  }

  .no-office {
    text-align: center;
    line-height: 30px;
  }
}
