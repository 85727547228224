error {
  .component-style;
  @lineHeight: 70px;

  .hasFatalError {
    .fatal.overlay {
      display: block;
      right: 0;
      bottom: 0;
      height: auto;

      .error-display {
        opacity: 1;
        position: absolute;
        margin-top: 0;
        @height: 2 * @lineHeight + 1px;
        margin-top: -(@height / 2);
        height: @height;
        .transition(all 0.4s ease);
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.5);
    z-index: 100000000;
    overflow: hidden;
    height: 0;

    &.fatal {
      background: rgba(255,100,100,0.6);
    }

    .error-display {
      @backgroundColor: crimson;
      @foregroundColor: contrast(crimson);

      position: relative;
      top: 50%;
      width: 100%;
      overflow: auto;
      margin: 0 auto;
      background: @backgroundColor;
      color: @foregroundColor;
      opacity: 0;
      .transition(all 0.2s ease);
      .box-shadow(0 1px 3px 1px black);

      @height: 2 * @lineHeight + 1px;
      margin-top: -(@height / 2) - 20px;

      .row {
        line-height: @lineHeight;
        width: 100%;
        text-align: right;
        .box-sizing(border-box);
        position: relative;
        text-align: center;
      }

      .row + .row {
        border-top: 1px solid darken(@backgroundColor, 10%);
      }

      .error {
        font-weight: bold;
        font-size: 20px;
        width: 100%;
      }

      button {
        cursor: pointer;
        .button-color(darken(@backgroundColor, 10%));

        em {
          font-weight: bold;
          font-style: normal;
          color: @foregroundColor;
        }
      }
    }
  }
}
