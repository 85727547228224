uploaded-document {
  display: block;
  position: relative;
  .clearblock;
  clear: both;
  margin-top: 10px;
  padding: 10px 0;
  border: 1px solid #ddd;
  background: white;

  .no-file {
    opacity: 0.6;
    font-size: 13px;
  }

  .col, .col-right {
    float: left;
  }

  @leftCol: 30%;
  .col {
    width: @leftCol;
  }

  .col-right {
    width: 100% - @leftCol;
  }

  .row-label {
    text-align: right;
    padding-right: 30px;
  }

  .file-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 40px;
    line-height: 52px;
  }

  .doc-row {
    .clearblock;
    margin: 5px 0;

    .file-name {
      max-width: 65%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      float: left;
      margin-right: 5px;
    }

    .size {
      font-size: 12px;
    }
  }

  .note {
    margin-right: 70px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #f5f5f5;
  }

  .uploaded-document {
    display: block;
    .clearblock;
  }
}

