footer {
  @footer-bg-color: @primaryColor;
  @footer-fg-color: #f5c8b9;

  border-top: 1px solid darken(@header-bg-color, 20%);
  .clearblock;
  background: @footer-bg-color;
  color: @footer-fg-color;

  & > .content {
    .clearblock;
    max-width: @page-width;
    margin: 0 auto;
  }

  .left, .right {
    float: left;
    min-height: 10px;
  }

  @leftWidth: 0%;
  .left {
    width: @leftWidth;
  }
  .right {
    width: 100% - @leftWidth;
  }

  .connect-with-us {
    float: right;
    margin-right: 20px;
    padding-top: 10px;

    & > .title, & > .content {
      line-height: 30px;
    }

    & > .title {
      text-transform: uppercase;
    }

    & > .content {
      a {
        display: block;
        width: 40px;
        height: 35px;
        float: right;
        opacity: 0.66;
        margin-left: 5px;

        &:before {
          margin: 0;
        }

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 1;
        }

        &.linkedin, &.youtube, &.gplus, &.mail {
          background: url('/images/social-logos.png') no-repeat 0px 0px transparent;
          border-radius: 3px;
        }

        &.linkedin {
          background-position: 0px -96px;
        }
        &.youtube {
          background-position: 0px 0px;
        }
        &.gplus {
          background-position: 0px -46px;
        }
        &.mail {
          background-position: 0px -137px;
        }
      }
    }
  }

}
