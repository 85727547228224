password-reset-form {
  .component-style;

  form {
    float: left;

    label {
      display: block;
      .clearblock;
      line-height: 28px;

      @textWidth: 30%;

      .text {
        display: block;
        float: left;
        width: 200px;
        text-align: right;
        padding-right: @spacing;
        width: @textWidth;
        .box-sizing(border-box);
      }

      input[type="text"], input[type="password"] {
        width: 100% - @textWidth;
        .box-sizing(border-box);
      }
    }

    label + label {
      margin-top: 5px;
    }

    button {
      float: right;
      margin-top: @spacing;
    }
  }

  .password-reset {
    .email {
      font-weight: bold;
    }
  }

  .login.button {
    margin-top: @spacing;
    float: right;
  }
}
