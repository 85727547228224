.application-welcome {
  h3, h4 {
    margin-top: 0;
  }

  .well {
    .clearblock;
  }

  .start-now {
    float: right;
    .glyphicon {
      margin-left: 5px;
    }
  }
}
