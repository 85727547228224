.admin.page {
  padding-top: @pageSpacing;
  padding-bottom: @pageSpacing;
  .clearblock;

  .admin-title {
    line-height: 30px;
    font-weight: bold;
    font-size: 17px;
    text-align: right;
    color: #777;

    & + hr {
      margin-top: 10px;
    }

    span {
      color: black;
    }
  }

  & > .content {
    position: relative;
  }

  .admin-menu {
    position: relative;
    overflow: hidden;
    opacity: 1;
    padding-bottom: 10px;

    a.app-section {
      line-height: 30px;
      font-size: 14px;
      float: left;
      padding: 0 10px;
      cursor: pointer;
      border-radius: 3px;
      color: white;
      background: #a7a7a7;

      .glyphicon + span {
        margin-left: 10px;
      }

      &.active, &:hover {
        background: #009fff;
        text-decoration: none;
      }
    }

    a + a {
      margin-left: 5px;
    }
  }
}
