.application-menu {
  @textColor: mix(@body-bg-color, @body-text);
  padding-left: 0;
  margin-top: 40px;

  .app-section {
    display: block;
    line-height: 30px;
    text-align: right;
    color: @textColor;
    font-size: 16px;
    font-weight: bold;
    cursor: default;
    font-weight: normal;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .app-section {
    opacity: 0.5;

    &.active {
      color: @primaryColor;
    }

    &.usable {
      opacity: 1;
    }
  }

  a.app-section {
    &:hover, &:focus {
      color: mix(@body-bg-color, @body-text, 30%);
    }

    &.usable {
      cursor: pointer;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }

  .section {
    .clearblock;
    clear: both;

    .sub {
      @subBackground: #efefef;
      font-weight: normal;
      padding: 5px;
      border-radius: 3px;
      border: none;
      background: @subBackground;
      margin-bottom: 10px;

      .app-section {
        font-size: 12px;
        line-height: 17px;
        font-weight: normal;
        color: mix(@textColor, white, 70%);

        &.active {
          color: @primaryColor;
        }
      }
    }
  }

  .sub.app-section + .app-section {
    margin-top: 5px;
  }

  .app-section + .sub.app-section {
    border-top: 1px solid #eee;
  }

  &.isMasquerading {
    .app-section {
      opacity: 1;
    }
    a.app-section {
      cursor: pointer;

      &:hover, &:focus {
        color: mix(@body-bg-color, @body-text, 30%);
      }
    }
  }
}

.application-menu + outlet {
  padding-right: 0;
  padding-left: 0;
}
