notification {
  display: block;
  .clearblock;

  .notification {
    position: relative;

    a {
      display: block;
      font-size: 13px;
      text-align: right;
      margin-top: 10px;
    }
  }
}
