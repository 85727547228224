.contact-us.page {
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .content {
    .clearblock;
  }

  .col {
    float: left;
    width: 50%;
    box-sizing: border-box;
  }

  .right.col {
    padding-left: 20px;
  }
}
