.profile.page {
  padding-top: @pageSpacing;
  padding-bottom: @pageSpacing;
  .clearblock;

  .page.title {
    margin: 0;
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 20px;
  }

  & > .content {
    position: relative;
    .clearblock;
    @profile-image-width: 320px;

    .col {
      float: left;
      box-sizing: border-box;
      .clearblock;

      &.left {
        width: @page-width - @profile-image-width - 20px;
        float: left;
      }
      &.right {
        float: right;
        width: @profile-image-width - 20px;
        padding: 0 0 10px 20px;
      }
    }

    .status {
      padding: 10px;
      border-radius: 3px;
      color: white;
      text-align: center;

      .display {
        font-weight: bold;
        white-space: nowrap;
      }
    }

    .profile-image {
      margin-top: 20px;
      text-align: center;

      .title {
        font-weight: bold;
        margin-bottom: 10px;
      }

      img {
        max-width: 300px - 20px;
        border: 1px solid #777;
        border-radius: 3px;
        background: #e2e2e2;
      }
    }

    .menu {
      .item {
        @item-base-color: #e9f1ff;
        @item-border-color: darken(@item-base-color, 10%);
        @item-padding: 5px;

        background: @item-base-color;
        border: 1px solid @item-border-color;
        margin-bottom: 10px;
        border-radius: 3px;

        a {
          display: block;
          border-bottom: 1px solid @item-border-color;
          padding: @item-padding;
          color: darken(@item-base-color, 40%);

          &:hover {
            text-decoration: none;
            color: darken(@item-base-color, 70%);
          }
        }

        .description {
          padding: 16px 16px 0;

          a {
            border: none;
            padding: 10px;
          }
        }

        p {
          padding: 0 @item-padding;
          margin-bottom: 0;
        }

        ul {
          margin-bottom: 0;
        }

        p + p {
          margin-top: 10px;
        }
      }
    }

    iframe {
      width: 100%;
      height: 700px;
      border: 1px solid #aaa;
      background: #f2f2f2;
    }
  }
}
