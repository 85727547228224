password-reset-request {
  .component-style;

  form {
    float: left;

    label {
      display: block;
      .clearblock;
      line-height: 28px;
      @textWidth: 25%;

      .text {
        display: block;
        float: left;
        text-align: right;
        padding-right: @spacing;
        width: @textWidth;
        .box-sizing(border-box);
      }

      input[type="text"] {
        width: 100% - @textWidth;
        .box-sizing(border-box);
      }
    }

    button {
      float: right;
      margin-top: @spacing;
    }
  }

  .email-sent {
    .email {
      font-weight: bold;
    }
  }

  .login.button {
    margin-top: @spacing;
    float: right;
  }
}
