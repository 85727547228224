assistant-information {
  display: block;
  .clearblock;
  background: white;
  border: 1px solid @table-border-color;

  .title {
    text-align: right;
    font-size: 13px;
    padding: 0 @padding-large-horizontal;
    line-height: 30px;
    font-weight: bold;
    border-bottom: 1px solid @table-border-color;

    span {
      color: @primaryColor;
    }
  }

  .content {
    padding: @padding-large-vertical @padding-large-horizontal;

    .remove-assistant, .save-assistant {
      float: right;
    }

    .save-assistant {
      margin-left: 10px;
    }
  }
}

assistant-information + assistant-information {
  margin-top: 20px;
}
