.fadeIn {
  opacity: 0; }

.animateIn > .fadeIn {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease; }

.fadeInDown {
  opacity: 0;
  -webkit-transform: translate3d(0, -50px, 0);
     -moz-transform: translate3d(0, -50px, 0);
          transform: translate3d(0, -50px, 0); }

.animateIn > .fadeInDown {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInDownBig {
  opacity: 0;
  -webkit-transform: translate3d(0, -200px, 0);
     -moz-transform: translate3d(0, -200px, 0);
          transform: translate3d(0, -200px, 0); }

.animateIn > .fadeInDownBig {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInLeft {
  opacity: 0;
  -webkit-transform: translate3d(-50px, 0, 0);
     -moz-transform: translate3d(-50px, 0, 0);
          transform: translate3d(-50px, 0, 0); }

.animateIn > .fadeInLeft {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInLeftBig {
  opacity: 0;
  -webkit-transform: translate3d(-200px, 0, 0);
     -moz-transform: translate3d(-200px, 0, 0);
          transform: translate3d(-200px, 0, 0); }

.animateIn > .fadeInLeftBig {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInRight {
  opacity: 0;
  -webkit-transform: translate3d(50px, 0, 0);
     -moz-transform: translate3d(50px, 0, 0);
          transform: translate3d(50px, 0, 0); }

.animateIn > .fadeInRight {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInRightBig {
  opacity: 0;
  -webkit-transform: translate3d(200px, 0, 0);
     -moz-transform: translate3d(200px, 0, 0);
          transform: translate3d(200px, 0, 0); }

.animateIn > .fadeInRightBig {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInUp {
  opacity: 0;
  -webkit-transform: translate3d(0, 50px, 0);
     -moz-transform: translate3d(0, 50px, 0);
          transform: translate3d(0, 50px, 0); }

.animateIn > .fadeInUp {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.fadeInUpBig {
  opacity: 0;
  -webkit-transform: translate3d(0, 200px, 0);
     -moz-transform: translate3d(0, 200px, 0);
          transform: translate3d(0, 200px, 0); }

.animateIn > .fadeInUpBig {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

@-moz-keyframes flip {
  from {
    -moz-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
         transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -moz-animation-timing-function: ease-out;
         animation-timing-function: ease-out; }
  40% {
    -moz-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
         transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -moz-animation-timing-function: ease-out;
         animation-timing-function: ease-out; }
  50% {
    -moz-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
         transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -moz-animation-timing-function: ease-in;
         animation-timing-function: ease-in; }
  80% {
    -moz-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
         transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -moz-animation-timing-function: ease-in;
         animation-timing-function: ease-in; }
  to {
    -moz-transform: perspective(400px);
         transform: perspective(400px);
    -moz-animation-timing-function: ease-in;
         animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
       -moz-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
       -moz-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
       -moz-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
       -moz-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
       -moz-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
       -moz-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
       -moz-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
       -moz-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
       -moz-transform: perspective(400px);
            transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
       -moz-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
     -moz-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-animation-name: flip;
     -moz-animation-name: flip;
          animation-name: flip; }

.flipInX {
  -webkit-backface-visibility: visible !important;
     -moz-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
     -moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
          transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
  opacity: 0; }

.animateIn > .flipInX {
  -webkit-transform: perspective(400px);
     -moz-transform: perspective(400px);
          transform: perspective(400px);
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease;
  opacity: 1; }

.flipInY {
  -webkit-backface-visibility: visible !important;
     -moz-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
     -moz-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
          transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
  opacity: 0; }

.animateIn > .flipInY {
  -webkit-transform: perspective(400px);
     -moz-transform: perspective(400px);
          transform: perspective(400px);
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease;
  opacity: 1; }

.lightSpeedIn {
  -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
     -moz-transform: translate3d(100%, 0, 0) skewX(-30deg);
          transform: translate3d(100%, 0, 0) skewX(-30deg);
  opacity: 0; }

.animateIn > .lightSpeedIn {
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.rotateIn {
  -webkit-transform-origin: center;
     -moz-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate3d(0, 0, 1, -200deg);
     -moz-transform: rotate3d(0, 0, 1, -200deg);
          transform: rotate3d(0, 0, 1, -200deg);
  opacity: 0; }

.animateIn > .rotateIn {
  -webkit-transform-origin: center;
     -moz-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.rotateInDownLeft {
  -webkit-transform-origin: left bottom;
     -moz-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
     -moz-transform: rotate3d(0, 0, 1, -45deg);
          transform: rotate3d(0, 0, 1, -45deg);
  opacity: 0; }

.animateIn > .rotateInDownLeft {
  -webkit-transform-origin: left bottom;
     -moz-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.rotateInDownRight {
  -webkit-transform-origin: right bottom;
     -moz-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
     -moz-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
  opacity: 0; }

.animateIn > .rotateInDownRight {
  -webkit-transform-origin: right bottom;
     -moz-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.rotateInUpLeft {
  -webkit-transform-origin: left bottom;
     -moz-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
     -moz-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
  opacity: 0; }

.animateIn > .rotateInUpLeft {
  -webkit-transform-origin: left bottom;
     -moz-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.rotateInUpRight {
  -webkit-transform-origin: right bottom;
     -moz-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
     -moz-transform: rotate3d(0, 0, 1, -90deg);
          transform: rotate3d(0, 0, 1, -90deg);
  opacity: 0; }

.animateIn > .rotateInUpRight {
  -webkit-transform-origin: right bottom;
     -moz-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
.rollIn {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
     -moz-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
          transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }

.animateIn > .rollIn {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.zoomIn {
  opacity: 0;
  -webkit-transform: scale3d(0.3, 0.3, 0.3);
     -moz-transform: scale3d(0.3, 0.3, 0.3);
          transform: scale3d(0.3, 0.3, 0.3); }

.animateIn > .zoomIn {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.zoomInDown {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -400px, 0);
     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -400px, 0);
          transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -400px, 0); }

.animateIn > .zoomInDown {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.zoomInLeft {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-400px, 0, 0);
     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-400px, 0, 0);
          transform: scale3d(0.1, 0.1, 0.1) translate3d(-400px, 0, 0); }

.animateIn > .zoomInLeft {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.zoomInRight {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(400px, 0, 0);
     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(400px, 0, 0);
          transform: scale3d(0.1, 0.1, 0.1) translate3d(400px, 0, 0); }

.animateIn > .zoomInRight {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.zoomInUp {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 400px, 0);
     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 400px, 0);
          transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 400px, 0); }

.animateIn > .zoomInUp {
  opacity: 1;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease, -moz-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.slideInDown {
  -webkit-transform: translate3d(0, -50px, 0);
     -moz-transform: translate3d(0, -50px, 0);
          transform: translate3d(0, -50px, 0);
  visibility: visible; }

.animateIn > .slideInDown {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -moz-transition: transform 0.5s ease, -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.slideInLeft {
  -webkit-transform: translate3d(-50px, 0, 0);
     -moz-transform: translate3d(-50px, 0, 0);
          transform: translate3d(-50px, 0, 0);
  visibility: visible; }

.animateIn > .slideInLeft {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -moz-transition: transform 0.5s ease, -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.slideInRight {
  -webkit-transform: translate3d(50px, 0, 0);
     -moz-transform: translate3d(50px, 0, 0);
          transform: translate3d(50px, 0, 0);
  visibility: visible; }

.animateIn > .slideInRight {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -moz-transition: transform 0.5s ease, -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

.slideInUp {
  -webkit-transform: translate3d(0, 50px, 0);
     -moz-transform: translate3d(0, 50px, 0);
          transform: translate3d(0, 50px, 0);
  visibility: visible; }

.animateIn > .slideInUp {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -moz-transition: transform 0.5s ease, -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease; }

viewModel, dataModel, router, outlet {
  display: block; }
