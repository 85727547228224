.ink {
  display: block; position: absolute;
  background: rgba(255,255,255,0.5);
  border-radius: 100%;
  transform: scale(0);
}

.ink.animate {
  animation: ripple 0.65s linear;
}

@keyframes ripple {
  100% { opacity: 0; transform: scale(2.5) }
}

.has-rippler {
  overflow: hidden;
  position: relative;

  .rippler {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
