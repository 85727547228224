admin-case-summary + admin-case-summary {
  margin-top: 5px;
}

admin-case-summary {
  display: block;
  clear: both;
}

.admin-case-summary {
  @caseSize: 30px;
  @borderRadius: 3px;
  @secondaryHeight: 25px;
  @startingOpacity: 1;
  @fadeoutTransition: all 0.4s 0.3s ease;

  position: relative;
  border: 1px solid @border-color;
  border-radius: @borderRadius;
  line-height: @caseSize;
  background: fadeout(white, 30%);
  padding-left: @idWidth;
  height: @caseSize + @secondaryHeight;
  .clearblock;

  .state-display {
    position: absolute;
    left: 0;
    bottom: 0;
    width: @idWidth;
    line-height: @secondaryHeight;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0 3px 0 3px;
    opacity: @startingOpacity;
    transition: @fadeoutTransition;

    &.queued {
      background: #6892e0;
      color: white;
    }
    &.accepted {
      background: #4ac588;
      color: white;
    }
    &.declined {
      background: #e06868;
      color: white;
    }
  }

  @referredToLabelWidth: 90px;
  @memberNameLabelWidth: 200px;
  .secondary {
    position: relative;
    clear: both;
    font-size: 12px;
    line-height: @secondaryHeight;
    padding-left: @referredToLabelWidth + @memberNameLabelWidth;
    padding-right: 160px;

    .referral-info {
      width: 100%;
    }

    .to {
      color: #888;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .from-label {
      position: absolute;
      left: 0;
      top: 0;
      line-height: @secondaryHeight;
      width: @memberNameLabelWidth;
      color: #888;
      padding-left: 5px;

      .name {
        color: black;
      }
    }

    .referred-to-label {
      position: absolute;
      left: @memberNameLabelWidth;
      top: 0;
      line-height: @secondaryHeight;
      width: @referredToLabelWidth;
      text-align: right;
      box-sizing: border-box;
      padding-right: 5px;
    }
  }

  @idWidth: 110px;
  .col {
    float: left;
    height: 100%;
    text-align: center;
    box-sizing: border-box;

    &.id {
      width: @idWidth;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      opacity: @startingOpacity;
      transition: @fadeoutTransition;
    }

    &.area-of-law {
      width: 55%;
      text-align: left;
      padding-left: 5px;
    }

    &.created-at {
      width: 20%;
    }

    &.controls {
      width: 25%;
      position: absolute;
      top: 0;
      right: 0;
      line-height: @secondaryHeight + @caseSize;
      height: @secondaryHeight + @caseSize;
      z-index: 1;

      a {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0.7;

        &:hover {
          text-decoration: none;
          opacity: 1;
        }

        span + span {
          margin-left: 5px;
        }
      }
    }
  }

  &.label-row {
    background: none;
    border: none;
    font-weight: bold;


    .id {
      opacity: 1;
    }

    &:hover {
      background: none;
    }
  }

  &:hover {
    border-color: darken(@border-color, 20%);
    background: white;

    .state-display {
      opacity: 1;
      transition: all 0s linear;
    }

    .col {
      opacity: 1;
      transition: all 0s linear;
    }
  }
}
