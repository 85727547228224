admin-attorney-credential-status {
  .clearblock;
  clear: both;

  @backgroundColor: #ddd;
  @color: contrast(@backgroundColor);
  @lightColor: #666;

  margin-top: 5px;
  color: @color;

  .admin-attorney-credential-status {
    border-radius: 3px 0 0 3px;
    border: 1px solid transparent;
    position: relative;

    .num-notes {
      font-size: 11px;
      color: #aaa;
    }

    .notes {
      padding: 0 10px 5px 0;

      admin-attorney-credential-status-note + admin-attorney-credential-status-note {
        margin-top: 5px;
      }
    }

    .toggle-expansion {
      position: absolute;
      width: 20px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    & > .content {
      line-height: 20px;
      margin-left: 20px;
      padding: 5px 0 5px 10px;
      background: white;
      color: black;
      .clearblock;

      .state-date {
        float: right;
        font-size: 12px;
        color:  @lightColor;
        padding-right: 10px;
      }

      .set-by {
        margin-top: 5px;
        font-size: 12px;
        color:  @lightColor;
      }
    }

    .new-note {
      width: 100%;
      padding: 5px 10px 5px 0;
      .clearblock;

      textarea {
        width: 100%;
        max-width: 100%;
        min-height: 50px;
      }

      button {
        float: right;
      }
    }

    .view-snapshot {
      margin: 5px 0;

      .link {
        font-size: 12px;
        color:  @lightColor;
        padding: 3px 5px 3px 0;
        user-select: none;

        span {
          margin-right: 5px;
        }

        &:hover {
          color: @color;
        }
        &:active {
          color:  @lightColor;
        }
        cursor: pointer;
      }
    }
  }
}
