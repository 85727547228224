required-document {
  display: block;
  .clearblock;
  position: relative;

  .cancel.button {
    opacity: 0.7;

    &:hover, &:active, &:focus {
      opacity: 1;
    }
  }

  .contrib-data {
    margin: 0 0 20px;
  }

  .money {
    @moneyPadding: 15px;
    position: relative;

    .dollar-sign {
      position: absolute;
      left: 16px;
      top: 0;
      width: @moneyPadding - 1px;
      line-height: 28px;
      text-align: center;
      color: #999;
    }

    input {
      padding-left: @moneyPadding;
    }
  }

  .label {
    font-weight: bold;
  }

  .loading-spinner {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
  }

  .required-document {
    @spacing: 20px;

    & > .glyphicon-refresh {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;

      &.visible {
        opacity: 1;
        visibility: visible;
        transition: all 0.2s ease;
      }
    }

    .file-label {
      font-weight: bold;
      font-size: 20px;
    }

    & > .description {
      margin-top: @spacing;
    }

    .source-download {
      margin-bottom: @spacing;
      width: 100%;
      margin-top: 5px;
      .clearblock;

      a {
        line-height: 30px;

        &:hover span {
          text-decoration: underline;
        }

        span {
          display: block;
          float: left;
        }

        .glyphicon {
          color: black;
          font-size: 30px;
          margin-right: 5px;
        }
      }
    }

    & > form {
      .clearblock;
      display: block;
    }
  }

  .file-upload-button {
    float: left;
    display: block;
    height: 0;
    overflow: hidden;

    &.isVisible {
      height: auto;
      overflow: visible;
    }

    input[type="file"] {
      position: absolute;
      pointer-events: none;
    }
  }

  .selected-file-name {
    padding: 0 10px 10px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: white;
    float: left;
    width: 100%;
    clear: both;

    @lineHeight: 40px;

    .file-icon {
      font-size: 30px;
      line-height: @lineHeight;
      margin-left: 5px;
    }

    hr {
      margin: 0 0 10px 0;
    }

    .selected-file {
      line-height: @lineHeight;
      white-space: nowrap;
      font-weight: bold;
      .clearblock;

      .file-name-container {
        .clearblock;
      }

      .file-name-container, .row-label, .file-name, .file-icon {
        float: left;
        box-sizing: border-box;
      }

      @filenameLabelWidth: 25%;
      .row-label {
        padding-right: 5px;
        width: @filenameLabelWidth;
        text-align: right;
      }
      .file-name-container {
        width: 100% - @filenameLabelWidth;
      }
    }

    textarea {
      min-height: 70px;
      max-width: 100%;
      margin-bottom: 10px;
    }

    .submit-row {
      text-align: right;

      .upload-now.button {
        &.isUploading {
          opacity: 0.7;
        }
      }
    }

    .file-name {
      color: @primaryColor;
      font-size: 17px;
      line-height: @lineHeight;
      max-width: 88%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  form {
    margin-top: 10px;
  }
}

