.expandable-color(@color) {
  &.expanded > .expand-toggle .inner {
    background: @color;
  }

  .expand-toggle-content {
    .inner {
      background: -moz-linear-gradient(top,  fade(@color,0%) 0%, fade(@color,100%) 41%, fade(@color,100%) 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,fade(@color,0%)), color-stop(41%,fade(@color,100%)), color-stop(100%,fade(@color,100%))); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top,  fade(@color,0%) 0%,fade(@color,100%) 41%,fade(@color,100%) 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top,  fade(@color,0%) 0%,fade(@color,100%) 41%,fade(@color,100%) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top,  fade(@color,0%) 0%,fade(@color,100%) 41%,fade(@color,100%) 100%); /* IE10+ */
      background: linear-gradient(to bottom,  fade(@color,0%) 0%,fade(@color,100%) 41%,fade(@color,100%) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
  }
}

p + .expandable {
  margin-top: @spacing;
}

.expandable {
  @expandableStartHeight: 30px;

  height: @expandableStartHeight;
  overflow: hidden;
  display: block;
  position: relative;
  clear: both;
  .expandable-color(@body-bg-color);

  &.bordered.expanded > .expand-toggle {
    .inner {
      border-top: 1px solid @border-color;
    }
  }

  &.fullyCollapsible {
    &.collapsed {
      .expand-toggle-content {
        .not-button {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    .expand-toggle {
      .not-button.control {
        bottom: 0;
      }
    }
  }

  &.ready {
    .transition(height 0.3s ease);
  }

  .expand-toggle {
    .not-button.control {
      // bottom: -5px;
    }
  }

  & > .content {
    position: relative;
    .clearblock;
  }

  .inner {
    .clearblock;
    width: 100%;
    height: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .expand-toggle-content {
    .clearblock;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  @expandableHeight: 40px;
  .expand-toggle {
    position: absolute;
    width: 100%;
    display: block;
    right: 0;
    bottom: 0;
    z-index: 1;
    line-height: @expandableHeight;
    text-align: right;
    cursor: pointer;

    &:hover .not-button {
      .text {
        text-decoration: underline;
      }
    }

    .control {
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      font-size: 12px;

      &.not-button {
        padding-right: 10px;
      }

      &.button:hover {
        .icon + span {
          text-decoration: none;
        }
      }

      .icon + span {
        padding-left: 5px;
      }
    }
  }
}
