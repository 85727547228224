.dashboard.page {
  @menuWidth: 20%;
  @titleHeight: 40px;
  padding-top: @pageSpacing;
  padding-bottom: @pageSpacing;

  .dashboard-menu {
    float: left;
    width: @menuWidth;
    .box-sizing(border-box);

    a {
      @background: white;
      @foreground: #666;
      @buttonSpacing: 5px;
      @itemHeight: @titleHeight;

      display: block;
      text-align: right;
      margin: 0 @buttonSpacing @buttonSpacing 0;
      line-height: @itemHeight;
      height: @itemHeight;
      color: @foreground;
      .box-sizing(border-box);

      .glyphicon {
        float: left;
        line-height: @itemHeight;
        padding-left: 10px;
        font-size: 15px;
        opacity: 0.5;
      }

      .rippler {
        padding-right: @spacing;
      }
    }
  }

  .dashboard {
    float: left;
    width: 100% - @menuWidth;
    .clearblock;

    & > .content {
      min-height: 400px;

      .name {
        font-weight: bold;
      }

      ul {
        margin-top: @spacing;

        li {
          list-style-type: disc;
          margin-left: 2em;
        }

        li + li {
          margin-top: 5px;
        }
      }
    }
  }
}
