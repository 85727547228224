.attorney-areas-of-law {
  display: block;
  .clearblock;

  & > h3 {
    margin-top: 0;
  }

  .row + .row {
    margin-top: 20px;
  }

  .note {
    font-weight: bold;
    margin-bottom: 0;
  }

  .note + .note {
    margin-top: 10px;
  }

  .total-percentage {
    margin-top: 10px;
    background: @primaryColor;
    color: white;
    float: left;
    padding: 10px;
    border-radius: 3px;

    &.is100Percent {
      background: #1CBF22;

      .percentage-sign {
        color: white;
      }
    }
  }

  .total-value {
    font-weight: bold;
  }

  .percentage-sign, .over-percentage {
    color: mix(@primaryColor, white, 30%);
  }

  .over-percentage {
    margin-left: 5px;
    padding-left: 10px;
    border-left: 1px solid white;

    .glyphicon {
      color: white;
      text-shadow: 0px 1px 0px black;
    }
  }

  .specializations {
    @spacing: 15px;

    margin-top: @spacing;
    background: white;
    padding: @spacing 0;

    attorney-specialization + attorney-specialization {
      margin-top: @spacing;
      border-top: 1px solid #ddd;
      padding-top: @spacing;
    }
  }

  .no-results {
    text-align: center;
    line-height: 40px;
  }
}
