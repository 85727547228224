.firm-information {
  h3 {
    margin-top: 0;
  }

  .query + * {
    margin-top: 20px;
  }

  .query {
    @lineHeight: 25px;
    @iconWidth: 38px;
    @transitionTime: 0.3s;
  }

  .practice-type {
    opacity: 0.7;

    margin: 5px 5px 0 0;

    &:hover, &.active {
      opacity: 1;
    }
  }

  .firmName {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  hr {
    display: block;
    clear: both;
  }

  .note {
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
  }

  .find-firm {
    float: right;
  }

  .additional-info.row {
    margin-top: 20px !important;
    line-height: 30px;
  }

  .firm {
    @borderColor: #ddd;

    padding: 10px;
    border-radius: 3px;
    background: white;
    border: 1px solid @borderColor;
    position: relative;
    .clearblock;
    line-height: 40px;

    &:hover {
      border-color: darken(@borderColor, 15%);
    }

    .select-firm {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .firm-label {
      font-weight: bold;
      width: 25%;
      float: left;
    }
  }

  .row + .firm, .firm + .firm {
    margin-top: 10px;
  }

  .found-firms {
    margin-top: 20px;

    .note {
      font-size: 13px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 20px;
      border-top: 1px solid #ddd;
      padding-top: 10px;
    }
  }

  form.firm-info {
    .no-spacing {
      margin-bottom: 0;
    }
  }

  .input-group {
    width: 100%;

    & > span {
      padding: 0 10px;
    }

    input, select, textarea {
      box-shadow: none;
    }
  }

  .address.input-group {
    & > span {
      width: 33%;
    }
  }
}

@media (max-width: @screen-md) {
  .spaced {
    margin-top: 5px;
  }

  .city-state-zip .input-group-addon {
    width: 33%;
  }
}

@media (min-width: @screen-md) {
  .city-state-zip {
    padding-left: 0;

    &:last-child {
      padding-right: 0;
    }
  }
}
