@noNotificationColor: #aaa;
@hasNotificationColor: red;
@notificationsBackground: white;

notifications {
  display: block;
  float: left;
  .clearblock;

  .toggle {
    cursor: pointer;
    color: @noNotificationColor;
    user-select: none;

    &:hover {
      color: black;
    }
  }

  .notifications {
    position: relative;

    &.hasNotifications {
      .toggle {
        color: @hasNotificationColor;
      }
    }
  }

  .notification-list {
    position: absolute;
    top: 100%;
    right: 0;
    background: @notificationsBackground;
    border: 1px solid darken(@notificationsBackground, 10%);
    box-shadow: 0 1px 23px -4px black;
    padding: 10px;
    border-radius: 3px;
    width: 300px;
    z-index: 10;
  }
}
