.attorney-profile {
  form {
    max-width: 100%;
    width: 400px;

    .row {
      .clearblock;
    }

    @textWidth: 25%;
    @rowHeight: 30px;

    .single-input {
      label {
        float: left;
        width: @textWidth;
        text-align: right;
        padding-right: @spacing;
        line-height: @rowHeight;
        height: @rowHeight;
        .box-sizing(border-box);
      }

      .input {
        float: left;
        width: 100% - @textWidth;
        .box-sizing(border-box);
      }
    }

    .dual-input {
      label {
        float: left;
        width: @textWidth;
        text-align: right;
        padding-right: @spacing;
        line-height: @rowHeight;
        height: @rowHeight;
        .box-sizing(border-box);
      }

      .input {
        float: left;
        width: (100% - @textWidth) / 2;
        .box-sizing(border-box);
      }

      .input + .input {
        padding-left: 5px;
      }
    }

    .name-input {
      @middleInitialWidth: 10%;

      label {
        float: left;
        width: @textWidth;
        text-align: right;
        padding-right: @spacing;
        line-height: @rowHeight;
        height: @rowHeight;
        .box-sizing(border-box);
      }

      .input {
        float: left;
        width: ((100% - @textWidth - @middleInitialWidth) / 2);
        .box-sizing(border-box);

        &.mi {
          width: @middleInitialWidth;
        }
      }

      .input + .input {
        padding-left: 5px;
      }
    }

    input[type="text"], input[type="password"], textarea, select {
      display: block;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      line-height: @rowHeight;
      height: @rowHeight;
      border: 1px solid @border-color;
      .box-sizing(border-box);

      &:active, &:focus {
        border-color: black;
      }
    }

    .row + .row {
      margin-top: @spacing;
    }

    .submit.row {
      text-align: right;
    }
  }
}
