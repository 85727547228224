.change-password.page {
  padding-top: @pageSpacing;
  padding-bottom: @pageSpacing;
  .clearblock;

  .page.title {
    margin: 0;
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 20px;
  }

  & > .content {
    position: relative;
    .clearblock;
  }

  a {
    display: block;
    float: left;
    clear: both;
  }

  .reason {
    font-weight: bold;
    color: @primaryColor;
  }

  .changed {
    font-weight: bold;
  }

  form {
    width: 75%;
    margin: 0 auto;
    margin-top: 20px;

    label {
      display: block;
      .clearblock;
      line-height: 28px;

      @textWidth: 30%;

      .text {
        display: block;
        float: left;
        width: 200px;
        text-align: right;
        padding-right: @spacing;
        width: @textWidth;
        .box-sizing(border-box);
      }

      input[type="text"], input[type="password"] {
        width: 100% - @textWidth;
        .box-sizing(border-box);
      }
    }

    label + label {
      margin-top: 5px;
    }

    button {
      float: right;
      margin-top: @spacing;
    }
  }
}
