@inputErrorColor: #BF4949;

.validator {
  position: relative;

  &.invalid {
    input {
      border-color: @inputErrorColor;
    }
  }

  .input-error {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 0;
    margin-top: 5px;
    background: @inputErrorColor;
    color: contrast(@inputErrorColor);
    border: 1px solid darken(@inputErrorColor, 10%);
    box-shadow: 0 1px 3px -1px black;
    border-radius: 3px;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    min-width: 100px;
  }
}

input.invalid {
  border-color: @inputErrorColor !important;
}
