admin-attorney-credential-status-note {
  @backgroundColor: #ECE7CD;
  @textColor: contrast(@backgroundColor);
  @borderColor: #ddd;
  @lightTextColor: mix(@backgroundColor, @textColor);

  clear: both;
  .clearblock;

  .admin-attorney-credential-status-note {
    .clearblock;
    padding: 5px;
    background: @backgroundColor;

    .title {
      border-bottom: 1px solid darken(@lightTextColor, 3%);
      padding-bottom: 5px;
      margin-bottom: 5px;
      .clearblock;
    }

    .timestamp, .author {
      font-size: 11px;
      color: @lightTextColor;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }

    @timestampWidth: 55%;
    .timestamp {
      max-width: @timestampWidth;
    }

    .author {
      max-width: 100% - @timestampWidth;
      float: right;
      padding-left: 5px;
    }
  }
}
