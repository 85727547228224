preferred-area {
  display: block;
  .clearblock;
  float: left;
  width: 50%;

  .preferred-area {
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    background: white;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    &.visible {
      border: 1px solid transparent;
      margin-right: 10px;
      opacity: 1;
      width: auto;
      height: auto;
      padding: 0 5px;
      margin-bottom: 10px;
      line-height: 30px;
    }

    &.isPreferred {
      border-color: @primaryColor;
      transition: all 1.3s ease;
    }

    input {
      position: relative;
    }
  }
}
