@legaleasewidth: 150px;
@legaleaseheight: 54px;
@headerTransition: all 0.5s 0.3s ease;
@top-header-height: @header-height - @nav-height;

header {
  position: relative;
  height: @header-height;
  .box-sizing(border-box);
  background: @header-bg-color;
  color: @headerTextColor;
  .transition(@headerTransition);

  .loggedin-user-display {
    @containerHeightSpacing: 5px;
    @containerHeightPadding: 5px;
    @containerHeight: @top-header-height - (@containerHeightSpacing * 2) - (@containerHeightPadding * 2);

    display: none;
    float: right;
    position: absolute;
    height: @containerHeight;
    right: 20px;
    top: @containerHeightSpacing;
    padding: @containerHeightPadding 0 @containerHeightPadding @containerHeightPadding;
    // border: 1px solid black;
    // border-radius: 3px;
    box-sizing: content-box;


    @profileImageSize: @containerHeight;
    .profile-image {
      z-index: 1;
      position: absolute;
      bottom: 0;
      right: 0;
      top: @containerHeightPadding;
      width: @profileImageSize;
      height: @profileImageSize;

      img {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -(@profileImageSize / 2);
        max-width: 100%;
        max-height: 100%;
        border-radius: @profileImageSize;
        border: 1px solid #666;
        background: #E2E2E2;
      }

      notifications {
        position: absolute;
        bottom: -10px;
        right: 0px;
      }
    }

    .message {
      position: relative;
      float: right;
      z-index: 2;
      line-height: @containerHeight - 20px;
      margin-right: @profileImageSize + @containerHeightPadding;
    }

    &.loggedIn {
      display: block;
    }
  }

  .logos {
    height: 100%;
    float: left;
    .clearblock;
    height: @header-height - @nav-height;

    .logo {
      float: left;
      position: relative;

      &.legalease {
        width: @legaleasewidth;
        height: 100%;
      }
    }
  }

  .legalease img {
    position: absolute;
    top: 50%;
    margin-top: -(@legaleaseheight / 2);
  }

  .legalease img.beta {
    left: 100%;
  }

  & > .content, & > .lower-content > .content {
    max-width: @pageMaxWidth;
    margin: 0 auto;
    position: relative;
    padding: 0 20px;
    .box-sizing(border-box);
    .clearblock;
  }

  .lower-content {
    background: @header-bar-color;
    color: @header-bar-text-color;
    overflow: hidden;
    height: @nav-height;
    transition: @headerTransition;
    position: relative;

    .shadow {
      display: none;
      position: absolute;
      top: 0;
      width: 100%;
      pointer-events: none;
      background: url('/images/header-shadow.png') no-repeat center top;
      height: 18px;
    }

    .glyphicon {
      position: relative;
      top: 2px;
    }

    & > .content {
      height: 100%;
      padding: 0;
    }

    .inner {
      position: absolute;
      bottom: 0;
      padding: 0 20px;
      width: 100%;
      .box-sizing(border-box);
    }
  }
}

.csspointerevents .lower-content .shadow {
  display: block !important;
}
