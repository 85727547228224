.case.page {
  & > .content {
    .clearblock;

    & > .title {
      @titleHeight: 30px;

      font-size: 20px;
      line-height: @titleHeight;
      margin-bottom: 20px;
      .clearblock;

      .referral {
        float: right;
        font-size: 13px;
        border-radius: 3px;
        line-height: @titleHeight;
        padding: 0 5px;
        border: 1px solid @border-color;
        background: white;
        color: #777;

        .ref-code {
          font-weight: bold;
          color: black;
        }
      }

      .area-of-law {
        font-weight: bold;
      }
    }

    .referred-attorney {
      margin-bottom: 20px;
      font-size: 15px;

      & > .title {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 17px;
      }

      .firm-name {
        font-size: 13px;
      }

      .row {
        margin: 0;
      }
    }

    .wants-claim-form {
      .message {
        font-weight: bold;
        margin-bottom: 5px;
      }

      .cancel.button {
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }

    .requesting-claim-form, .requested-claim-form {
      font-size: 15px;
      font-weight: bold;

      .glyphicon {
        margin-right: 5px;
      }
    }

    .requested-claim-form .glyphicon {
      color: @primaryColor;
    }

    .choose-benefit {
      @upArrowWidth: 100px;

      position: relative;
      padding: 20px 10px 20px @upArrowWidth;

      .up-arrow {
        position: absolute;
        top: 0;
        left: 0;
        width: @upArrowWidth;
        text-align: center;
        margin-left: -5px;
      }

      .glyphicon {
        font-size: 65px;
        position: relative;
        color: #999;
        position: relative;
      }

      font-weight: bold;
      font-size: 18px;
    }

    .new.benefit .choose-benefit {
      .glyphicon {
        top: 5px;
        font-size: 75px;
      }
    }

    .back.button {
      margin-bottom: 20px;

      &.last {
        clear: both;
        margin-top: 20px;
      }
    }

    .abort-benefits-review {
      margin-top: 10px;
    }

    .saving-case {
      font-weight: bold;
      font-size: 15px;

      .glyphicon {
        margin-right: 5px;
      }
    }

    .case-accepted {
      font-weight: bold;
      color: @primaryColor;
      font-size: 20px;
      margin-bottom: 10px;

      .glyphicon {
        margin-right: 5px;
      }
    }

    p.title {
      font-weight: bold;
      margin-top: 20px;
    }

    .benefits {
      & > .title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 5px;
      }
    }

    .benefit-selector {
      option:first-child {
        font-weight: bold;
        background: #efefef;
      }
    }

    .signature-request {
      .clearblock;
      background: #f7fbc9;
      padding: 10px;
      border-radius: 3px;

      input {
        margin: 5px 0;
      }

      .message {
        font-weight: bold;
      }

      .initials {
        font-weight: bold;
      }

      button {
        opacity: 0.6;

        &.usable {
          opacity: 1;
        }
      }
    }

    .benefit {
      .details {
        padding: 10px;
        border: 1px solid #c1c1c1;
        margin-top: 10px;
        background: white;
        border-radius: 3px;

        & > .title {
          font-size: 17px;
          font-weight: bold;

          .glyphicon {
            margin-right: 10px;
          }
        }

        .item-label {
          font-weight: bold;
        }

        .hourlyRate .amount, .totalPayment .amount {
          color: green;
          font-weight: bold;
        }

        .item + .item {
          margin-top: 10px;
        }
      }
    }

    pre {
      float: left;
    }

    hr {
      clear: both;
    }

    .declined.answer {
      font-size: 15px;

      .glyphicon {
        color: @primaryColor;
        margin-right: 5px;
      }

      pre {
        margin-top: 10px;
        clear: both;
        display: block;
        float: none;
      }

      .decline-reason {
        font-weight: bold;
      }
    }

    .desired-office {
      clear: both;
      padding-top: 10px;

      pre {
        margin-right: 5px;
      }

      .note {
        font-weight: bold;
      }

      p {
        clear: both;
      }
    }

    .options {
      clear: both;
      margin-top: 20px;

      & > .message {
        font-size: 20px;
        font-weight: bold;
      }

      .option {
        margin-top: 10px;
        padding: 0 10px 10px 10px;
        border-radius: 3px;
        background: #ddd;

        & > .title {
          line-height: 40px;
        }

        button {
          .glyphicon {
            margin-right: 5px;
          }
        }

        &.decline {
          margin-top: 20px;

          button {
            width: 540px;
            max-width: 100%;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .wants-to-decline {
            .reason {
              margin-top: 5px;

              .decline-reason {
                font-weight: bold;
                padding: 5px;
                border-radius: 3px;
                background: white;
              }

              .refusal-note {
                margin-top: 10px;
              }

              textarea {
                margin-top: 10px;
                width: 400px;
                max-width: 100%;
                min-height: 100px;
              }
            }

            .confirm {
              margin-top: 20px;

              & > .message {
                font-weight: bold;
                margin-bottom: 10px;
              }

              button {
                width: auto;
              }
            }
          }
        }

        &.accept {
          button {
            font-size: 17px;
            line-height: 40px;
            font-weight: bold;
            padding: 0 15px;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .glyphicon {
              margin-right: 10px;
            }
          }

          .note {
            margin-top: 5px;
            clear: both;
            float: left;
          }
        }

        & > .title {
          font-weight: bold;
        }

        & > .content {
          .clearblock;
        }

        button {
          float: left;
          clear: both;
        }

        button + button {
          margin-top: 5px;
        }
      }
    }
  }

  case-list + case-list {
    margin-top: 20px;
  }
}
