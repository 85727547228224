main {
  box-sizing: border-box;

  & > .content {
    min-height: 900px;

    .page > .content {
      max-width: @page-width;
      margin: 0 auto;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .file-upload-button {
    input[type=file] {
      visibility: hidden;
      height: 0;
    }
  }
}
