office {
  .clearblock;

  @scheduleBackground: #444;
  @scheduleText: contrast(@scheduleBackground);
  @togglerBackground: darken(@scheduleBackground, 20%);

  pre {
    margin: 0;
  }

  @leftColWidth: 40%;
  @contactItemHeight: 25px;

  input, select {
    outline-style: none;
    transition: none !important;

    &:focus, &:active {
      outline-style: none !important;
      box-shadow: none !important;
      border: 1px solid black !important;
      transition: none !important;
    }
  }

  .city-row {
    padding: 0;
    margin-bottom: 5px;
  }

  .contact-item {
    width: 100%;
    .clearblock;
    display: block;
    line-height: @contactItemHeight;

    span {
      display: inline-block;
      width: @leftColWidth;
      text-align: right;
      float: left;
      padding: 0 5px 0 0;
      box-sizing: border-box;
      line-height: @contactItemHeight - 2px;
    }

    input {
      display: block !important;
      float: left;
      width: (100% - @leftColWidth) !important;
      box-sizing: border-box;
      height: @contactItemHeight;
      line-height: @contactItemHeight;
    }

    .input-group {
      width: (100% - @leftColWidth);
      float: right;
      font-size: 12px !important;

      input[type="text"] {
        line-height: @contactItemHeight;
        height: @contactItemHeight;
      }
    }

    .input-group-addon {
      font-size: 12px !important;
    }
  }

  .make-primary {
    font-size: 12px;
    cursor: pointer;
    color: #888;

    &:hover {
      color: black;
    }
    &:active {
      color: #444;
    }
  }

  .office-hours {
    float: left;
    position: relative;

    .toggler {
      @togglerHeight: 30px;

      font-size: 12px;
      padding: 0 5px;
      line-height: @togglerHeight;
      height: @togglerHeight;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      position: relative;

      .done.button {
        @doneButtonHeight: 20px;

        line-height: @doneButtonHeight;
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -(@doneButtonHeight / 2) - 1px;

        .glyphicon {
          line-height: @doneButtonHeight - 2px;
        }
      }
    }

    .schedule {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      z-index: 20;
      background: @scheduleBackground;
      color: @scheduleText;
      border-radius: 3px;
      box-shadow: 0 1px 3px -1px black;

      .toggler {
        background: @togglerBackground;
        color: contrast(@togglerBackground);
        border-radius: 3px 3px 0 0;
        cursor: auto;
        padding-left: 6px;

        .glyphicon {
          padding-right: 2px;
        }
      }

      .close-label {
        cursor: pointer;
      }
    }

    &.showOfficeHours {
      .schedule {
        display: block;
      }
    }
  }

  .office-hours .schedule {
    @scheduleLineHeight: 30px;
    @dayNameWidth: 20%;
    @borderColor: #ddd;

    width: 450px;
    font-size: 13px;
    line-height: @scheduleLineHeight;

    & > .content {
      .clearblock;
    }

    .name {
      float: left;
      width: @dayNameWidth;
      height: @scheduleLineHeight;
      text-align: right;
      padding-right: 5px;
      box-sizing: border-box;
    }

    .hours {
      float: left;
      width: 100% - @dayNameWidth;

      &.no {
        padding-left: 5px;
        font-size: 12px;
        line-height: @scheduleLineHeight;
        color: mix(@scheduleText, @scheduleBackground);
      }
    }

    .day {
      @dayBorderColor: darken(@scheduleBackground, 5%);

      position: relative;
      .clearblock;
      border-top: 1px solid darken(@dayBorderColor, 5%);
      border-bottom: 1px solid darken(@dayBorderColor, 5%);

      &:hover {
        background: lighten(@scheduleBackground, 5%);
      }

      &:last-child {
        border-radius: 0 0 3px 3px;
      }
    }

    .day + .day {
      border-top-width: 0;
    }

    .add-day {
      position: absolute;
      top: 0;
      right: 0;
      line-height: @scheduleLineHeight;
      padding: 0 5px;
      opacity: 0.7;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 1;
      }
      &:active {
        color: darken(contrast(@scheduleBackground), 10%);
      }
    }
  }

  .remove-office {
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    line-height: 30px;
    float: right;
    margin-right: 10px;
    color: #888;

    &:hover {
      color: black;
    }
    &:active {
      color: #444;
    }
  }

  .mutable.office {
    .clearblock;
  }

  .office-mutate {
    margin-top: 10px;
    position: relative;

    .hours-notice {
      max-width: 50%;
      float: left;
      font-size: 12px;
      line-height: 15px;
    }

    .button {
      float: right;
    }
  }

  .selectable {
    @line-height: 15px;

    font-weight: normal;
    line-height: 15px;
    font-size: 13px;
    user-select: none;
    margin-top: 10px;
    text-align: right;
    float: right;
    padding: 10px 15px 10px 10px;
    color: #777;
    cursor: pointer;

    &.selected, &:hover {
      color: black;
    }

    input {
      height: @line-height;
      line-height: @line-height;
      margin: 0;
      position: relative;
      top: 2px;
    }
  }
}

office + office {
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
