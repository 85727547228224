admin-attorney-credential-manager {
  clear: both;

  @borderColor: #ddd;
  @scrollAreaBackground: #f5f5f5;
  @areaHeight: 500px;

  .admin-attorney-credential-manager {
    .clearblock;
    height: @areaHeight;

    .backgroundBackupRequired {
      color: crimson;

      &.selected {
        color: black;
      }
    }

    .popup-container {
      float: left;
      position: relative;
      .clearblock;

      .popup {
        position: absolute;
        z-index: 100;
        left: 100%;
        margin-left: 5px;
        top: 5px;
        line-height: 20px;
        font-size: 12px;
        pointer-events: none;
        font-weight: normal;
        background: #D9F9FF;
        width: 200px;
        box-shadow: 0 1px 3px black;
        border-radius: 3px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, top 0.3s ease;
      }

      &:hover {
        .popup {
          opacity: 1;
          visibility: visible;
          display: block;
          padding: 10px;
          top: 5px;
          transition: opacity 0.3s ease, top 0.3s ease;
        }
      }
    }

    .view-pdf {
      font-size: 14px;
      padding-left: 10px;
      cursor: pointer;
      font-weight: normal;
      color: #777;
      border: 1px solid transparent;
      display: block;
      float: left;

      &:hover {
        color: black;
      }

      .glyphicon {
        font-size: 20px;
        padding-right: 5px;
        position: relative;
        top: 2px;
      }
    }

    .outer-content {
      width: 100%;
      transition: all 0.3s ease;
      overflow: hidden;
      height: @areaHeight;
    }

    .inner-content {
      .clearblock;
      position: relative;
      width: 858px;
      float: right;
    }

    .review-col {
      float: left;
    }

    .snapshot-col {
      float: right;
      width: 0%;
      transition: all 0.3s ease;
      height: @areaHeight;

      .inner-content {
        width: 100%;
        min-height: 200px;
        float: left;
      }
    }

    &.showSnapshot {
      .review-col {
        width: 0%;
        transition: all 0.3s ease;
      }

      .snapshot-col {
        width: 100%;
        transition: all 0.3s ease;
      }
    }

    .close-snapshot {
      @color: #ddd;

      position: absolute;
      top: 0;
      right: 0;
      padding: 0 10px;
      font-size: 20px;
      color: @color;
      cursor: pointer;
      line-height: 44px;

      &:hover {
        color: black;
      }
      &:active {
        color: @color;
      }
    }

    .snapshot-display {
      @snapshotHeight: 457px;

      & > .title {
        line-height: 40px;
        font-size: 20px;
        padding: 0px 10px 0px 10px;
        border-bottom: 1px solid #a2a2a2;
        box-sizing: border-box;
        margin-bottom: 0;
        .clearblock;

        & > .text {
          float: left;
        }
      }

      & > .content {
        position: relative;
        height: @snapshotHeight;
      }

      iframe {
        width: 100%;
        height: @snapshotHeight;
        border: none;
        transition: opacity 0.3s ease;
        opacity: 1;
        background: #e2e2e2;
        box-shadow: 0 15px 15px -17px black inset;
      }

      .loading-snapshot {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        font-size: 15px;
        margin-top: 50px;
        width: 100%;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s ease;

        .glyphicon {
          margin-right: 10px;
        }
      }

      &.snapshotLoading {
        iframe {
          opacity: 0;
          visibility: none;
          transition: opacity 0.3s ease;
        }

        .loading-snapshot {
          visibility: visible;
          opacity: 1;
          height: auto;
          transition: opacity 0.3s ease;
        }
      }
    }
  }

  label {
    cursor: pointer;
  }

  .sub-section + .sub-section {
    margin-top: 10px;
  }

  uploaded-document {
    .file-icon {
      top: auto;
      font-size: 25px;
      line-height: 25px;
      height: 25px;
    }

    .col-right {
      width: 80%;
      overflow: hidden;

      .file-name {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .review, .status {
    float: left;
    .clearblock;
    box-sizing: border-box;

    & >.title {
      line-height: 28px;
    }
  }

  @reviewWidth: 50%;
  .review {
    width: @reviewWidth;
    padding: 10px 0;

    & > .title {
      padding: 0 10px 10px 10px;
      margin-bottom: 0;
    }

    & > .content {
      background: @scrollAreaBackground;
      border: 1px solid @borderColor;
      border-left-width: 0;
      padding: 10px;
      max-height: 400px;
      overflow: auto;

      & > .popup-container {
        width: 100%;
        margin-bottom: 10px;

        .popup {
          left: 25px;
          top: 100%;
          margin-top: 5px;
        }
      }

      a.snapshot {
        display: block;
        padding: 5px 10px;

        .glyphicon {
          margin-right: 10px;
        }
      }

      required-document {
        border-bottom: none;
        margin-bottom: 0;

        .required-document > form {
          .selected-file-name {
            padding: 10px;
          }
        }

        uploaded-document {
          border: none;
          margin-top: 0;
          background: transparent;

          .note {
            background: white;
            margin-right: 0;
            max-height: 75px;
            overflow: auto;
          }
        }
      }
    }

    & > .button {
      float: right;
      margin: 10px 10px 0 0;
    }

    admin-attorney-credential-file {
      .note {
        margin-right: 0;
        font-size: 12px;
        max-height: 75px;
        overflow: auto;
      }

      .doc-row {
        margin: 0;

        .row-label {
          display: none;
        }
      }
    }

    admin-attorney-credential-file + admin-attorney-credential-file {
      margin-top: 5px;
    }

    required-document {
      margin-bottom: 10px;
      border-bottom: 1px solid @borderColor;

      .well {
        padding: 0px;
        background: transparent;
        border: none;
        margin-bottom: 10px;
        box-shadow: none;

        uploaded-document {
          border: none;
          padding: 0;
          margin: 0;
        }

        .file-label {
          font-size: 15px;
          font-weight: normal;
        }

        form {
          margin: 0;

          .button {
            margin: 0;
          }
        }

        .doc-row {
          margin: 0;

          .row-label {
            display: none;
          }

          .file-name {
            max-width: none;
          }
        }

        .file-name-container {
          width: 100%;
        }

        .selected-file {
          .row-label {
            display: none;
          }
          .file-icon {
            float: right;
          }
          .file-name {
            max-width: 85%;
          }
        }

        .selected-file-name {
          padding: 0;
          border: none;
        }
      }
    }

    .sub-section {
      & > .content {
        padding-left: 10px;
      }
    }
  }

  @statusPromptHeight: 100px;
  @statusPromptWithExecHeight: 130px;
  @statusListHeight: 413px; // If you change this value make sure you change the same value in admin-attorney-credential-manager.js
  @openTransition: all 0.3s ease;
  @closeTransition: all 0.3s ease;

  .status {
    width: 100% - @reviewWidth;
    padding-top: 10px;
    border-left: 1px solid @borderColor;

    @selectBackground: #f1f1f1;

    & > .title {
      margin: 0 10px;
      .clearblock;

      .next-action {
        float: right;
        font-weight: normal;
        font-size: 12px;

        input {
          width: 100px;
          background: @selectBackground;
        }
      }
    }

    .status-list {
      padding: 5px 10px;
      height: @statusListHeight;
      overflow-y: scroll;
      margin-top: 10px;
      border-top: 1px solid @borderColor;
      background: @scrollAreaBackground;
      transition: @closeTransition;
    }

    .set-status {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      margin-top: 10px;
      .clearblock;

      .col {
        float: left;
        box-sizing: border-box;
      }

      @selectColWidth: 60%;
      .select.col {
        width: @selectColWidth;

        select {
          width: 100%;
          background: @selectBackground;
        }
      }

      .set.col {
        width: 100% - @selectColWidth;
        padding-left: 5px;

        .button {
          width: 100%;
          text-align: center;
        }
      }

      .status-prompt {
        height: 0;
        width: 100%;
        clear: both;
        overflow: hidden;
        position: relative;
        transition: @closeTransition;

        & > .content {
          position: absolute;
          bottom: 0;
          padding: 10px 0;
          width: 100%;

          .new-state {
            color: @primaryColor;
            white-space: nowrap;
          }

          .message {
            font-weight: bold;
            overflow: hidden;
            max-width: 100%;
          }

          .executive-message {
            display: block;
            margin-top: 5px;
            color: crimson;
          }

          .control-buttons {
            width: 100%;
            text-align: right;
            margin-top: 10px;

            .cancel {
              opacity: 0.7;

              &:hover {
                opacity: 1;
              }
            }

            .yes {
              text-shadow: 0 1px 1px black;
              font-weight: bold;

              .glyphicon {
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .status.wantsToSetStatus {
    .status-prompt {
      height: @statusPromptHeight;
      transition: @openTransition;
    }

    .status-list {
      height: @statusListHeight - @statusPromptHeight;
      transition: @openTransition;
    }

    &.hasExecutiveNotice {
      .status-prompt {
        height: @statusPromptWithExecHeight;
      }

      .status-list {
        height: @statusListHeight - @statusPromptWithExecHeight;
      }
    }
  }

  label {
    input {
      position: relative;
      top: 2px;
    }
    span {
      margin-left: 5px;
    }
  }
}
