.button-color(@color) {
  @active-color: contrast(@color, white, black, 60%);
  @hover-color: contrast(@color, white, black, 60%);
  @normal-color: mix(contrast(@color, white, black, 60%), @color, 80%);

  background-color: lighten(@color, 10%);
  .gradient(@color, darken(@color, 5%), lighten(@color, 5%));
  color: @normal-color;
  border: 1px solid darken(@color, 5%);

  &:focus {
    background-color: @color;
    .gradient(@color, darken(@color, 15%), lighten(@color, 5%));
    border-color: darken( @color, 15% );
  }

  &:hover {
    background-color: lighten(@color, 20%);
    .gradient(@color, darken(@color, 0%), lighten(@color, 10%));
    color: @hover-color;

    &:active .icon {
      border-color: @active-color;
    }

    .icon {
      border-right: 1px solid @normal-color;
    }
  }

  &:active, &.active {
    background: @color;
    border-color: darken( @color, 15% );
    color: @active-color;
  }
}

.button {
  @button-padding: 8px;
  @button-height: 27px;

  display: inline-block;
  position: relative;
  zoom: 1;
  cursor: default;
  overflow: visible;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 @button-padding;
  font-size: 14px;
  line-height: @button-height;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 2px;
  min-width: 60px;

  &:before {
    margin: 0 8px 0 0;
  }
  &.small:before {
    margin: 0 5px 0 0;
  }

  &:hover {
    text-decoration: none;

    .icon {
      width: 28px;
      transition: margin-right 0.3s ease, width 0.2s ease;
      margin-right: @button-padding;

      &.icon-refresh {
        width: 23px;
      }
    }

    .shortcut {
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  &:active, &.active {
    position: relative;
    transition: background-color 0s linear;
    box-shadow: 0 1px 3px -1px black inset;
    .icon {
      transition: all 0s linear;
    }
  }

  .icon {
    display: inline-block;
    width: 0;
    font-size: 18px;
    vertical-align: -4px;
    text-align: left;
    overflow: hidden;
    margin-right: 0;
    transition: width 0.3s 0.3s ease, margin-right 0.3s 0.3s ease;

    &.icon-refresh {
      font-size: 15px;
      vertical-align: -3px;
    }
  }

  .shortcut {
    display: inline;
    float: none;
    border: 1px solid white;
    border: 1px solid rgba(255,255,255,0.5);
    opacity: 0.5;
    margin-left: 5px;
  }

  .button-color(@button-background);
}

.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear,
                               left bottom,
                               left top,
                               color-stop(0, @start),
                               color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
                                  @start,
                                  @stop);
  background: -moz-linear-gradient(center bottom,
                                   @start 0%,
                                   @stop 100%);
  background: -o-linear-gradient(@stop,
                                 @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}

.clearblock {
  display: block;

  &:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}

.component-style {
  display: block;
  .clearblock;
}
