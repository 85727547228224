// Force footer to the bottom:
// Based on: http://ryanfait.com/resources/footer-stick-to-bottom-of-page/

* {
  margin: 0;
}

html, body {
  height: 100%;
}

main {
  display: block;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -@footer-height;
}

footer, .push {
  height: @footer-height;
  box-sizing: border-box;
}
