timeblock + timeblock {
  border-top: none;
}

timeblock {
  @borderColor: #ddd;
  @lineHeight: 30px;

  display: block;
  width: 100%;
  height: @lineHeight;
  float: left;
  padding-left: 5px;

  form {
    display: block;
    height: 100%;
    width: 100%;
    .clearblock;

    select {
      @selectHeight: 20px;

      color: black;
      height: @selectHeight;
      line-height: @selectHeight;
    }

    .to-label {
      padding: 0 5px;
    }

    .from, .to-label, .to {
      float: left;
      line-height: @lineHeight;
      display: block;
      height: 100%;

      select {
        display: inline;
        float: none;
      }
    }

    .destroy {
      line-height: @lineHeight;
      opacity: 0.7;
      cursor: pointer;
      padding: 0 5px;
      user-select: none;

      &:hover {
        opacity: 1;
      }
      &:active {
        color: darken(white, 10%);
      }
    }
  }
}
