attorney-background-question {
  @numberSize: 25px;
  @spacing: 10px;

  display: block;
  .clearblock;

  .question {
    position: relative;
    padding-left: @numberSize + @spacing;

    .number {
      position: absolute;
      left: 0;
      top: 0;
      background: @primaryColor;
      color: white;
      border-radius: 20px;
      display: block;
      float: left;
      width: @numberSize;
      height: @numberSize;
      text-align: center;
      line-height: @numberSize;
      font-weight: bold;
    }
  }

  .answer {
    margin-top: 10px;
    padding-left: @numberSize + @spacing;

    label {
      cursor: pointer;
      font-weight: normal;
      margin: 0;
    }

    label + label {
      margin-left: 10px;
    }

    table {
      margin: 0;

      &.settlement {
        td {
          @rowHeight: 30px;
          @moneyWidth: 20px;

          position: relative;
          width: 30%;
          line-height: @rowHeight;

          @validationMessageColor: crimson;
          .validationMessage {
            position: absolute;
            top: 100%;
            left: 10px;
            margin-top: -5px;
            z-index: 3;
            background: @validationMessageColor;
            color: contrast(@validationMessageColor);
            padding: 5px 10px;
            border-radius: 3px;
            box-shadow: 0 1px 3px -1px black;
            line-height: 17px;
            font-size: 13px;

            &:after {
              bottom: 100%;
              left: 20px;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: transparent;
              border-bottom-color: @validationMessageColor;
              border-width: 10px;
              margin-left: -10px;
            }
          }

          .money-sign {
            position: absolute;
            top: @table-cell-padding;
            left: @table-cell-padding;
            line-height: @rowHeight;
            opacity: 0.6;
            width: @moneyWidth;
            text-align: center;
          }

          input {
            padding-left: @moneyWidth;
          }

          &:first-child {
            width: 10%;
          }
        }

        input {
          width: 100%;
        }
      }
    }
  }
}
